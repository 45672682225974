import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { Container } from '../../../common/layouts';
import { MainPageTitle } from '../../../common/typography';
import DropDown from '../../../components/dropbox/DropDown';
import Steps from '../../../components/steps/Steps';
import { themeDefault } from '../../../themes/default';
import styles from './qna.module.scss';
import axios from "axios";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import TextArea from "../../../components/input/TextArea";
import Select from "../../../components/input/Select";
import Radio from "../../../components/input/Radio";
import Checkbox from "../../../components/input/Checkbox";
import {useLocation, useNavigate} from "react-router-dom";
import {getInsertToken} from "../../../hooks/axiosInsertToken";

const Qna = () => {
    const location = useLocation();
    const [step, setStep] = useState(0); // step is 0, 1, 2, error = 2 && errorState true
    const [errorState, setErrorState] = useState(false);
    const [token, setToken] = useState<any>();
    const [stepBool, setStepBool] = useState<boolean>(false);
    const [values, setValues] = useState({
        // 0 step
        qna_name: '',
        phone_number: '',
        email: '',

        // 1 step
        title: '',
        message: '',

        device: '',
        category: '',
        reply_type: '',
    });
    /**
     * '{
     *    "category" : string;
     *     "qna_name" : string;
     *     "phone_number" : string;
     *     "email" : string;
     *     "title" : string;
     *     "message" : string;
     *     "reply_type" : string;
     * }'
     * BUG, PARTNER, INVESTMENT, ETC
     */
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();
    const submitHandler = (formValues:any) => {
        setSubmitting(true);
        setValues({
            ...values,
            ...formValues,
        });
        axios.post(`${process.env.REACT_APP_BASE_API}qna/add`, {
            qna_name: values.qna_name,
            phone_number: values.phone_number,
            email: values.email,
            insert_token: token,
            ...formValues,
        })
            .then((result) => {
                setSubmitting(false);
                if (result?.data?.status === 'OK') {
                    // alert('문의 접수가 완료되었습니다.');
                    // window.location.href = '/notice';
                    stepNext(2);
                }
            })
            .catch(error => {
                setErrorState(true);
                stepNext(2);
                setSubmitting(false);
            });
    }

    const isNotNumber = (value: any) => {
        const regExp = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
        return regExp.test(value);
    }

    const handleSubmit = (formValues: any) => {
        setValues({
            ...values,
            qna_name: formValues.qna_name,
            phone_number: formValues.phone_number,
            email: formValues.email,
            ...!formValues.email ? {
                reply_type: 'SMS'
            } : {
                reply_type: ''
            },
        });
        stepNext(1);
    };

    const stepNext = async (step: number) => {
        setStep(step);
        if (stepBool === false) {
            const data = await getInsertToken();
            if (data === 'TK01')
                alert("죄송합니다. 다시 시도해주세요");
            else
                setToken(data);
            setStepBool(true);
        }
    }

    const emailCheck = (email:string) => {
        const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regEmail.test(email);
    }

    const stepReset = () => {
        setStep(0);
        setErrorState(false);
        setValues({
          ...values,
          qna_name: '',
          phone_number: '',
          email: '',
  
          // 1 step
          title: '',
          message: '',
  
          device: '',
          category: '',
          reply_type: '',
      });
    }

    const ClickStep = (value: number) => {
        if (step === 2 && !errorState) return;
        setStep(value);
    }

  useEffect(() => {
    (location.pathname === '/howto/qna') && window.scrollTo({
      top: 0,
    })
  }, [location])

    // const form
    return (
        <QnaPage className={classNames(styles.qna_page, 'form-page')}>
            <Steps activeStep={step} ClickStep={ClickStep} />
            {
                step === 0 &&
                <>
                    <MainPageTitle>연락드릴 정보를 알려주세요!</MainPageTitle>
                    <div className="form">
                        <Formik
                            enableReinitialize
                            // @ts-ignore
                            initialValues={{
                                qna_name: values.qna_name || '',
                                phone_number: values.phone_number || '',
                                email: values.email || '',
                                personal_agree: false,
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                qna_name: Yup.string()
                                    .trim()
                                    .required('다시 확인해주세요.')
                                    .min(2, '이름은 2글자 이상 입력해주세요.'),
                                phone_number: Yup.string()
                                    .trim()
                                    .required("다시 확인해주세요.")
                                    .min(11, "핸드폰 번호는 '-' 를 제외한 11자리를 입력해주세요."),
                                email: Yup.string(),
                                personal_agree: Yup.boolean()
                                    .oneOf([true], '개인정보 수집이용에 동의해주세요.'),
                            })}
                        >
                            {({handleSubmit, isValid, dirty}) => (
                                <>
                                    <Field
                                        component={Input}
                                        label="이름"
                                        id="qna_name"
                                        name="qna_name"
                                        placeholder='이름 입력'
                                        required
                                    />
                                    <Field
                                        component={Input}
                                        label="휴대전화 번호"
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder='01012345678'
                                        required
                                        inputProps={{
                                            pattern: '[0-9]+'
                                        }}
                                    />
                                    <Field
                                        component={Input}
                                        label="이메일"
                                        id="email"
                                        name="email"
                                        placeholder='abcd@example.com'
                                        required
                                    />
                                    <Field
                                        component={Checkbox}
                                        label="문의 내용 답변을 위한 개인정보 수집이용에 동의합니다."
                                        id="personal_agree"
                                        name="personal_agree"
                                        required
                                    />
                                    <div className="alert-noti">잘못 입력하실 경우 답변을 받기 어려울수 있으니 다시 한번 확인 해주세요.</div>
                                    <div className="btn-wrap">
                                        {/*@ts-ignore*/}
                                        <div className={classNames("btn is-active", !isValid || !dirty ? 'is-disabled' : '')} onClick={handleSubmit} >다음</div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </>
            }
            {
                step === 1 &&
                <>
                    <MainPageTitle>무엇을 도와드릴까요?</MainPageTitle>
                    <Formik
                        initialValues={{
                            category: values.category || '',
                            device: values.device || '',
                            title: values.title || '',
                            message: values.message || '',
                            reply_type: values.reply_type || '',
                        }}
                        enableReinitialize
                        onSubmit={submitHandler}
                        validationSchema={Yup.object().shape({
                            category: Yup.string().required('카테고리를 선택해주세요'),
                            device: Yup.string()
                                .when('category', (category, schema) => {
                                    return category === 'BUG' ? schema.required('선택해주세요') : schema;
                                }),
                            title: Yup.string().required('문의 제목을 적어주세요.'),
                            message: Yup.string().required('문의 내용을 적어주세요.'),
                            reply_type: Yup.string().required('답변 방식을 선택해주세요.'),
                        })}
                    >
                        {({ handleSubmit, values: fieldValues, dirty, isValid }) => (
                            <>
                                <div className="form">
                                    <Field
                                        component={Select}
                                        label="카테고리"
                                        id="category"
                                        name="category"
                                        required
                                        type="select"
                                        placeholder="카테고리 선택"
                                        data={[
                                            { value: 'BUG', label: '뱅카우 사용 시 불편사항' },
                                            { value: 'INVESTMENT', label: '뱅카우 개선사항 건의' },
                                            { value: 'ETC', label: '기타' },
                                        ]}
                                    />
                                    {
                                        fieldValues?.category === 'BUG' && (
                                            <Field
                                                component={Radio}
                                                label="선택해주세요."
                                                name="device"
                                                wrapperClassName={{'is-left': emailCheck(values.email)}}
                                                options={[
                                                    {label: '안드로이드', value: 'Android'},
                                                    {label: 'IOS', value: 'iOS'},
                                                ]}
                                            />
                                        )
                                    }
                                    <Field
                                        component={Input}
                                        label="제목"
                                        id="title"
                                        name="title"
                                        placeholder='문의 제목을 입력해주세요.'
                                        required
                                    />
                                    <Field
                                        component={TextArea}
                                        label="문의 내용"
                                        id="message"
                                        name="message"
                                        placeholder='문의 내용을 입력해주세요.'
                                        required
                                    />
                                    <Field
                                        component={Radio}
                                        label="답변 받으실 방법을 선택해주세요."
                                        name="reply_type"
                                        options={[
                                            {label: '이메일 (E-mail)', value: 'EMAIL', disabled: !emailCheck(values.email)},
                                            {label: '문자 (MMS)', value: 'SMS'},
                                        ]}
                                    />
                                </div>
                                <div className="btn-wrap">
                                    {/*@ts-ignore*/}
                                    <div className={classNames("btn is-active", submitting || !isValid || !dirty ? 'is-disabled' : '')} onClick={handleSubmit} >다음</div>
                                    {/*onClick={() => stepNext(2)}*/}
                                </div>
                            </>
                        )}
                    </Formik>
                </>
            }
            {
                (step === 2 && !errorState) &&
                <>
                    <MainPageTitle>문의내용이 정상적으로 <br className="m-layer" />제출되었습니다.</MainPageTitle>
                    <p className='end-ment'>답변은 약 3일 이내로 확인하실 수 있습니다. <br />감사합니다!</p>
                    <div className={classNames("btn-wrap", `grade-${step}`)}>
                        <div className={classNames("btn is-active")} onClick={() => navigate(-1)}>확인</div>
                    </div>
                </>
            }
            {
                (step === 2 && errorState) &&
                <>
                    <MainPageTitle className={styles.error_title}>죄송합니다.</MainPageTitle>
                    <p className={classNames('end-ment', `state-${errorState}`)}>다시 시도해주세요.</p>
                    <div className={classNames("btn-wrap", `grade-${step}`)}>
                        <div className={classNames("btn is-active", `state-${errorState}`)} onClick={stepReset} >돌아가기</div>
                    </div>
                </>
            }
        </QnaPage >
    )
}

// @ts-ignore
const QnaPage = styled(Container)`
  ${MainPageTitle} {
    margin-top: 44px;
    margin-bottom: 60px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 24px;
      margin: 30px 0 48px 0;
      line-height: 28.6px;
    }
  }
`
// @ts-ignore
export default Qna;