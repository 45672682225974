import styled from 'styled-components';
// import { themeDefault } from '../../themes/default'
import { themeDefault } from '../themes/default';

type defaultType = {
	type?: string;
  gap?: number;	
}
// TODO: code refactoring
export const Flex = styled.div<defaultType>`
  display: flex;
  ${props => props.type === 'reverse'
	? 
    `flex-direction: row-reverse;
		`
	: 
		``
  }
  ${props => props.type === 'between'
	? 
    `justify-content: space-between;
		`
	: 
		``
  }
  ${props => props.type === 'column'
	? 
    `flex-direction: column;
		`
	: 
		``
  }
  gap: ${props => props.gap !== null ? props.gap + 'px' : 0};
`


export const Wrapper = styled.div`
	height: calc(100vh - 68px);
	background-color: ${(props) => props.theme.alt.backgroud1};
`;

export const Container = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  @media ${themeDefault.breakpoints.sm} {
		width: 100%;
	}
`

export const HomeTag = styled.span`
  color: #8EB0FF;
  font-size: 28px;
  font-weight: 700;
  @media ${themeDefault.breakpoints.sm} {
    font-size: 20px;
  }
`

export const PureInnerBox = styled.div`
	width: 80%;
	margin: 0 auto;
`;

export const InnerBox = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

export const RowSpaceBetween = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CenteredRowBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CenteredColBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const DownloadAppIconBox = styled.div`
	width: 200px;
	@media ${themeDefault.breakpoints.sm} {
		width: auto;
	}
`;

export const Radius30WhiteBox = styled.div`
	border-radius: 30px;
	background-color: ${themeDefault.colors.white};
	box-shadow: 2px 8px 20px 6px #E8EFFF;
  
`;

export const Radius30WhiteBox2 = styled.div`
	border-radius: 30px;
	box-shadow: 2px 8px 8px 1px rgba(102, 157, 254, 0.2);
`;

export const Radius30WhiteBox3 = styled.div`
	border-radius: 30px;
	box-shadow: 2px 8px 8px 1px rgba(102, 157, 254, 0.2);
`;

export const BackgroundImg = styled.div<{ url: string }>`
	background-image: url(${({ url }) => url});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const BackgroundImgAfter = styled.div<{ url: string }>`
  &:after {
    background: url(${({ url }) => url}) no-repeat center;
    background-size: cover;
  }
`;
export const BtnWrap = styled.div<{gap?: number}>`
  justify-content: center;
  gap: ${(props) => props.gap + 'px' || '20px'};
`