import React, {ChangeEventHandler, CSSProperties, HTMLInputTypeAttribute} from 'react'
import {FieldProps, getIn} from "formik";
import classNames from "classnames";

interface RadioProps extends FieldProps {
  id?: string|undefined,
  label?: any;
  disabled?: boolean|undefined;
  className?: string|undefined;
  inputChange?: ChangeEventHandler<any> | undefined;
  style?: CSSProperties|undefined;
  inputProps?: any|undefined;
  placeholder?: any;
  type?: HTMLInputTypeAttribute|undefined;
  required?: boolean|undefined;
  options: any[],
  valueKey?: string,
  labelKey?: string,
  wrapperClassName?: any,
}

const Radio = (props: RadioProps) => {
  const {
    field,
    form,
    id,
    label,
    disabled,
    style,
    inputChange,
    className,
    inputProps,
    placeholder,
    type = 'radio',
    required,
    options = [],
    valueKey = 'value',
    labelKey = 'label',
    wrapperClassName,
  } = props;
  const { name, value = '',  ...rest } = field;
  const { errors, touched, isValid, handleBlur, submitCount } = form;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  let showError = error && (touch || submitCount > 0);

  const handleChangeInput = (event: any) => {
    event.persist();

    const changeEvent:any = {
      target: {
        name: name,
        value: event.target.value
      }
    };
    field.onChange(changeEvent);

    inputChange && inputChange(changeEvent);
  };

  return (
      <div className={classNames(["inputwrap", {
        'is-false': showError,
      }])}>
        <strong>{label}</strong>
        <div className={classNames(['radio-wrap', wrapperClassName])}>
          {options && options.map((item) => (
              <React.Fragment key={item[valueKey]}>
                <label htmlFor={id} className='radio-label'>
                  <input
                      type={type} name={name} id={id}
                      onChange={handleChangeInput} placeholder={placeholder}
                      required={required}
                      onBlur={handleBlur}
                      disabled={disabled || item?.disabled}
                      value={item[valueKey]}
                      checked={value === item[valueKey]}
                  />
                  <span></span>
                  {item[labelKey]}
                </label>
              </React.Fragment>
          ))}
        </div>
        <div className="false-code">
          {error}
        </div>
      </div>
  )
}

export default Radio