import React from 'react'
import styles from './history.module.scss';

const History2020 = () => {
  return (
    <div className={styles.story}>
      <strong>2020</strong>
      <ul>
        <li>
          <span>12</span>스탁키퍼 이슈메이커 한국의 인물 선정
        </li>
        <li>
          <span>11</span>2020년 핀테크 공모전 대상 (금융위원장 수상) <br />GOURMET PREMIUM 브랜드 런칭
        </li>
        <li>
          <span>10</span>스탁키퍼(Stockeeper Co,. Ltd) 법인 전환
        </li>
        <li>
          <span>06</span>스탁키퍼(Stockeeper Co,. Ltd) 설립
        </li>
      </ul>
    </div>
  )
}

export default History2020