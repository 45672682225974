import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Footer from '../../common/global/Footer';
import { Container } from '../../common/layouts';
import { MainPageTitle } from '../../common/typography';
import DropDown from '../../components/dropbox/DropDown';
import Pagenation from '../../components/pagenation/Pagenation';
import { Link, useLocation } from "react-router-dom";
import styles from './notice.module.scss';
import { themeDefault } from '../../themes/default';
import axios from "axios";
import PrevArrow from '../../components/Slide/PrevArrow';
import NextArrow from '../../components/Slide/NextArrow';
import './notice.scss';
import Slider from 'react-slick';
import useBanner from "../../hooks/useBanner";
import ReactGA from 'react-ga';
import useDeviceDetect from "../../common/util/useDeviceDetect";

const tabItem = ['전체', '공지사항', '뉴스', 'SNS', '이벤트'];
const eventTypeTab = ['', 'NOTICE', 'NEWS', 'SNS', 'BANCOW_CONTENTS'];

type eventRequestProp = {
  "event_type": string,
  "title": string,
  "search_count": number,
  "search_page": number,
}

const PostLink = (props: any) => (
  // <Link to="/notice/[id]" as={`/notice/${props.id}`}>
  <Link to={{
    pathname: `detail/${props.eventId}`,
  }}>
    <div className="imgwrap">
      <img src={props?.imageUrl} alt="이미지" />
    </div>
    {
      props.tag &&
      <div className="tag">
        {props.tag === 'NOTICE' ?
            '공지사항' : props.tag === 'NEWS' ?
            '뉴스' : props.tag === 'BANCOW_CONTENTS' ?
            '이벤트' : props.tag !== '' ? props.tag : null
        }
      </div>
    }
    <div className={styles.title}>{props.title}</div>
    <div className={styles.date}>{props.date.split('T')[0].replace(/-/g, '.')}</div>
  </Link>
)

const Notice = () => {
  const location = useLocation();
  const { isMobile } = useDeviceDetect();
  const [tab, setTab] = useState(0);
  const [eventList, setEventList] = useState<any[]>([]);
  const [request, setRequest] = useState<eventRequestProp>({
    event_type: "",
    title: "",
    search_count: 6,
    search_page: 1
  });

  const bannerResult = useBanner();
  const [totalProp, setTotalProp] = useState({
    totalPages: 0,
    totalElements: 0
  });

  const fetchFaqList = () => {
    axios.post(`${process.env.REACT_APP_BASE_API}event/public/list`, request)
      .then((result) => {
        setEventList(result?.data?.data?.content);
        setTotalProp({
          ...totalProp,
          totalPages: result?.data?.data?.totalPages,
          totalElements: result?.data?.data?.totalElements
        });
      })
      .catch(error => error);
  }

  useEffect(() => {
    location.pathname === '/nowbancow' && window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },[location])

  useEffect(() => {
    fetchFaqList();

    return () => {
      setEventList([]);
    }
  }, [request]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const getPaging = (value: number) => {
    setRequest({
      ...request,
      search_page: value + 1
    })
  }

  const changeTab = (index: number) => {
    setTab(index);
    setRequest({
      ...request,
      search_count: 6,
      search_page: 1,
      event_type: eventTypeTab[index],
    });
  }

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    // dots: true,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 4,
    // slidesToScroll: 4,
    // initialSlide: 0,
  };

  return (
    <div className='nowbancow-page'>
      <div className={styles.key_visual} >
        <Slider {...settings} className={styles.nowbancow_slide}>
            {
              (!bannerResult?.isLoading && !bannerResult?.isError) &&
              bannerResult?.data?.data?.data.map((item, key) => {
                return (
                    <div className="imgwrap" key={key}>
                      {
                        (isMobile && item.eventId && item.eventId > 0) ? (
                            <Link to={{pathname: `detail/${item.eventId}`}}>
                              <img src={`${item.mobileImageUrl}`} alt="" key={key}/>
                            </Link>
                        ) : (isMobile && (!item.eventId || item.eventId <= 0)) ? (
                            <img src={`${item.imageUrl}`} alt="" key={key} />
                        ) : (!isMobile && item.eventId && item.eventId > 0) ? (
                            <Link to={{pathname: `detail/${item.eventId}`}}>
                              <img src={`${item.imageUrl}`} alt=""  key={key}/>
                            </Link>
                        ) : (
                            <img src={`${item.imageUrl}`} alt="" key={key} />
                        )
                      }
                    </div>
                )
              })
            }
        </Slider>
      </div>
      <Noticepage className={styles.notice_page}>
        <MainPageTitle>공지사항</MainPageTitle>
        <p>뱅카우의 최신소식을 함께해요!</p>
        <div className="tab pc-layer">
          {
            tabItem.map((item, index) => {
              return (
                <div
                  key={item}
                  className={classNames('tab-item', { 'is-select': index === tab })}
                  onClick={() => changeTab(index)}
                >
                  {item}
                </div>
              )
            })
          }
        </div>
        <div className="cardlist">
          {eventList.length > 0 &&
            eventList.map((e, idx) => {
              return (
                <Card className={'card'} tag={e.eventType} key={`${idx}-${e.id}`}>
                  <PostLink key={idx} id={idx} imageUrl={e.imageUrlList[0]} title={e.title} date={e.createDate} tag={e.eventType} eventId={e.id} />
                </Card>
              )
            })
          }
        </div>
        <Pagenation
            activeStep={request.search_page - 1}
            pagingCount={totalProp.totalPages}
            viewCount={request.search_count}
            getPaging={getPaging}
        />
      </Noticepage>
    </div>
  );
};

const Noticepage = styled(Container)`
  padding-bottom: 100px;
  @media ${themeDefault.breakpoints.sm} {
    padding-bottom: 38px;
  }
  .cardlist {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
    margin-bottom: 36px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 24px;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
    .imgwrap {
      border: 1px solid #eee;
    }
  }
  ${MainPageTitle} {
    margin-top: 100px;
    text-align: center;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 50px;
      font-size: 32px;
    }
  }
  p {
    margin-top: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 24px;
      font-size: 14px;
    }
  }
  .tab {
    display: flex;
    margin-top: 55px;
    margin-bottom: 80px;
  }
  .tab-item {
    display: table-cell;
    width: 100%;
    max-width: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    &.is-select {
      position: relative;
      font-weight: 700;
      &:after {
        position: absolute;
        background: #004BFA;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 2px;
        content:'';
      }
    }
  }
`

const Card = styled.div<{ tag?: string }>`
  width: 300px;
  height: 410px;
  cursor: pointer;
  @media ${themeDefault.breakpoints.sm} {
    margin: 0 auto;
    height: auto;
  }
  .imgwrap {
    width: 100%;
    height: 300px;
    background: #ccc;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media ${themeDefault.breakpoints.sm} {
      }
    }
  }
  .tag {
    display: inline-block;
    margin-top: 16px;
    padding: 1px 12px 0 12px;
    font-weight: 500;
    border-radius: 20px;
    line-height: 22px;
    @media ${themeDefault.breakpoints.sm} {
      margin-bottom: 0px;
    }
    ${props => props.tag === 'NOTICE'
    ?
    `background: #DCE6FF;
      `
    :
    ``
  }
    ${props => props.tag === 'BANCOW_CONTENTS'
    ?
    `background: #DCE6FF;
      `
    :
    ``
  }
    ${props => props.tag === 'NEWS'
    ?
    `background:  #DCFFF7;
		`
    :
    ``
  }
  ${props => props.tag === 'SNS'
    ?
    `background: #FFDCF5;
      `
    :
    ``
  }
  ${props => props.tag === 'BANCOW_CONTENTS'
    ?
    `background: #FFF1DC;
      `
    :
    ``
  }
  }
`

export default Notice;
