import CompanyInfo from "../footerItem/CompanyInfo";
import CompanyLinks from "../footerItem/CompanyLinks";
import styled from "styled-components";
import { Container, Flex, RowSpaceBetween } from "../../common/layouts";
import { themeDefault } from "../../themes/default";
import useDeviceDetect from "../util/useDeviceDetect";

type footerType = {
  themeType?: string;
};

const Footer = ({ themeType }: footerType) => {
  const { isMobile } = useDeviceDetect();
  return (
    <Section themeType={themeType}>
      <Container>
        <Flex type="column">
          <CompanyInfo themeType={themeType} />
          <CompanyLinks themeType={themeType} />
        </Flex>
        {!isMobile && (
          <Partner>
            (주) 스탁키퍼파트너스 | 대표 안재현 <br />
            서울특별시 중구 삼일대로12길 21 3층 <br />
            사업자등록번호 | 531-81-02137
          </Partner>
        )}
      </Container>
    </Section>
  );
};

const Partner = styled.div`
  margin-top: 12px;
  color: #999;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

const Section = styled.div<footerType>`
  padding: 44px 0 100px 0;
  transition: ease all 0.5s;
  ${(props) =>
    props.themeType === "dark"
      ? `
      // background: #282a2e;
      background: #101216;
      color: white;
		`
      : `
    border-top: 1px solid #ebebeb;
    `}
  @media ${themeDefault.breakpoints.sm} {
    padding: 35px 20px 104px 20px;
  }
  ${Flex} {
    @media ${themeDefault.breakpoints.sm} {
      flex-direction: column;
    }
  }
`;

export default Footer;
