import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames';
import CountUp, { useCountUp } from 'react-countup';
import { Container, Radius30WhiteBox, HomeTag, Flex } from '../../../common/layouts';
import { MainPageTitle } from '../../../common/typography';
import AreaChartC from '../../chart/AreaChartC';
import { themeDefault } from '../../../themes/default';
import './toggleview.scss';

const Stability = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ScrollY, setScrollY] = useState(0);  // 스크롤값을 저장하기 위한 상태

  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  })

  const toggleView = () => {
    setIsOpen(!isOpen)
  }

  return (
    <PageStyle>
      <Flex type="between" className="page_text">
        <Card className='card'>
          10년 전보다
          {/* <span>+20.0%</span> */}
          {
            ScrollY > 2500 ?
              <span>
                <CountUp start={0}
                  end={20}
                  prefix="+"
                  duration={1}
                  separator=" "
                  delay={0}
                  decimal="," />.0%
              </span> :
              <span>+20.0%</span>
          }
        </Card>
        <TextArea>
          <HomeTag>안정성</HomeTag>
          <MainPageTitle>한우시장은 <br />성장하는 안정적인<br className='m-layer' /> 시장이에요</MainPageTitle>
          <p className='desc1'>
            우리나라 한우는 <br />생산량도 활발히 증가하고 있어요!
          </p>
        </TextArea>
      </Flex>

      <ShowChart className={classnames('', { 'is-open': isOpen === true })}>
        {
          <div className='toggle-chart'>
            <div className="top-desc chart-desc">한우 사육두수</div>
            <AreaChartC data="growth" />
            <div className="bottom-desc chart-desc">출처: 가축동향조사 통계청</div>
          </div>
        }
        <div className="toggle-wrap">
          <Toggle onClick={toggleView} className="toggle_view">10년 그래프 {isOpen === true ? '닫기' : '펼쳐보기'}</Toggle>
        </div>
      </ShowChart>

      <Flex type="between" className="desc_wrap">
        <p className="desc">생산량이 늘어나면, <br />가격이 내려가는 것 아닌가요?</p>
        <Card className='mo-none card'>
          10년 전보다
          {
            ScrollY > 2800 ?
              <span>
                <CountUp start={0}
                  end={81}
                  prefix="+"
                  duration={1}
                  separator=" "
                  delay={0}
                  decimal="," />.2%
              </span> :
              <span>+81.2%</span>
          }
        </Card>
      </Flex>
    </PageStyle>
  )
}

const TextArea = styled.div`

`

const PageStyle = styled(Container)`
  margin-top: 130px;
  padding-bottom: 130px;
  overflow: hidden;
  @media ${themeDefault.breakpoints.sm} {
    padding-bottom: 0;
    margin-top: 120px;
  }
  .toggle-chart {
    margin-top: 26px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 30px;
    }
  }
  ${Flex} {
    align-items: center;
    position: relative;
    background: white;
    z-index: 2;
    @media ${themeDefault.breakpoints.sm} {
      align-items: start;
      gap: 30px;
    }
  }
  .desc1 {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    text-align: left;
    line-height: initial;
    @media ${themeDefault.breakpoints.sm} {
    }
  }
  ${TextArea} {
    @media ${themeDefault.breakpoints.sm} {
      padding-left: 20px;
    }
  }
  ${MainPageTitle} {
    margin-top: 23px;
    line-height: 54px;
    @media ${themeDefault.breakpoints.sm} {
      line-height: 36px;
      font-size: 28px;
    }
    + p {
      margin-top: 23px;
      color: #333;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      @media ${themeDefault.breakpoints.sm} {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
    }
  }
`

const Toggle = styled.div`
  
`

const Card = styled(Radius30WhiteBox)`
  display: flex;
  width: 500px;
  height: 192px;
  padding: 0 20px;
  font-size: 20px;
  color: #999;
  align-items: center;
  justify-content: space-between;
  @media ${themeDefault.breakpoints.sm} {
    width: calc(100% - 40px);
    height: 120px;
    margin: 0 auto;
    font-size: 16px;
  }
  span {
    font-weight: 700;
    color: #333;
    font-size: 80px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 36px;
    }
  }
`

const ShowChart = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -540px;
  transition: all 0.4s;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: -230px;
  }
  .toggle-wrap {
    position: relative;
    display: flex;
    justify-content: end;
    positoin: relative;
    margin-top: 142px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 50px;
    }
  }
  .toggle-chart {
    // display: none;
    display: block;
    .chart-desc {
      text-align: right;
      color: #999;
      @media ${themeDefault.breakpoints.sm} {
        font-size: 10px;
        margin-right: 20px;
      }
    }
    .bottom-desc {
      margin-top: 16px;
    }
  }
  &.is-open {
    margin-top: 0;
    .toggle-wrap {
      margin-top: 60px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 15px;
      }
    }
    .toggle-chart {
      display: block;
    }
    ${Toggle} {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
`



export default Stability