import Iframely from "../../components/iframely/Iframely";
import Constant from "../constant/Constant";

const Util = {
    // async processEmbedContent (text: string) {
    //     const embedRegex = /<\/oembed>/gi;
    //     const moreIndex = "</oembed>".length;
    //     const indices: (number | undefined)[] = [];
    //     const newTexts: any[] = [];
    //     let result;
    //     while ((result = embedRegex.exec(text))) {
    //         indices.push(result.index + moreIndex);
    //     }
    //     if (indices.length > 0) {
    //         indices.forEach((index, i) => {
    //             if (i === 0) {
    //                 newTexts.push(text.slice(0, index));
    //             } else if (index === indices.length - 1) {
    //                 newTexts.push(text.slice(index));
    //             } else {
    //                 newTexts.push(text.slice(indices[i-1], index));
    //             }
    //         })
    //         const regex = /(?<=<oembed)(.*?)(?=<\/oembed>)/gm;
    //         const matches = text.match(regex);
    //         if (matches && matches.length > 0) {
    //             for (let i = 0; i < matches.length; i++) {
    //                 const regex = /(?<=url=")(.*?)(?=")/gm;
    //                 const match = matches[i].match(regex);
    //                 if (match && match.length > 0) {
    //                     const html = await Iframely.load(match[0]);
    //                     if (html) {
    //                         newTexts[i] = `${newTexts[i]}${html}`;
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         newTexts.push(text);
    //     }
    //     return newTexts.join('');
    // },
    replaceEmbedContents(text:string) {
        let newText = text;
        const regex = /(?:url=")(.*?)(?=")/gm;
        const urls = newText.match(regex);
        if (urls && urls.length > 0) {
            for (let i = 0; i < urls.length; i++) {
                let match;
                const url = urls[i].replace('url="', "");
                // Youtube
                if ((match = url.match(Constant.REGEX.YOUTUBE))) {
                    if (match[2].length == 11) {
                        const toReplace = '<div class="iframe-container">' +
                            `<iframe src="https://www.youtube.com/embed/${ match[2] }" ` +
                            'class="iframe" ' +
                            'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                            '</iframe>' +
                            '</div>';
                        newText = newText.replace(
                            `<oembed url="${url}"></oembed>`,
                            `<oembed url="${url}"></oembed>${toReplace}`
                        );
                    }
                } else // DailyMotion
                if ((match = url.match(Constant.REGEX.DAILY_MOTION))) {
                    const toReplace = '<div class="iframe-container">' +
                        `<iframe src="https://www.dailymotion.com/embed/video/${ match[2] }" ` +
                        'class="iframe" ' +
                        'frameborder="0" allowfullscreen allow="autoplay">' +
                        '</iframe>' +
                        '</div>';
                    newText = newText.replace(
                        `<oembed url="${url}"></oembed>`,
                        `<oembed url="${url}"></oembed>${toReplace}`
                    );
                } else // Vimeo
                if ((match = url.match(Constant.REGEX.VIMEO))) {
                    const toReplace = '<div class="iframe-container">' +
                        `<iframe src="https://player.vimeo.com/video/${ match[1] }" ` +
                        'class="iframe" ' +
                        'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
                        '</iframe>' +
                        '</div>';
                    newText = newText.replace(
                        `<oembed url="${url}"></oembed>`,
                        `<oembed url="${url}"></oembed>${toReplace}`
                    );
                }
            }
        }
        return newText;
    },
}

export default Util;