import styled from "styled-components";
import React from "react";

type AppLoadingProps = {
    label?: string;
}

export default function AppLoading({label = 'Loading...'}:AppLoadingProps) {
    return (
        <Container>
            <div className="mt-16 loadings">
                <div className="loader"></div>
                <h3 className="ml-8">
                    {label}
                </h3>
            </div>
        </Container>
    )
}

const Container = styled.div`
  .mt-16 {
    margin-top: 16px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .loadings {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;