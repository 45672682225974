import React, { useEffect, useState } from 'react'
import axios from 'axios';
interface PopUpViewProps {
  closePopUp: (selCheck: boolean) => void;
  cookieState: (cookieCheck: any) => void;
}

const MainBanner = (props: PopUpViewProps) => {
  const [selCheck, setSelCheck] = useState<boolean>(false);
  const [getData, setGetData] = useState(null);
  const [url, setUrl] = useState(null);

  const onClosePopUp = (): void => {
    props.closePopUp(selCheck);
  }

  const onCheck = (): void => {
    props.cookieState(true);
  }

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_BASE_API;
    axios.get(`${apiUrl}popup/public`)
    .then(res => {
      setGetData(res?.data?.data?.image);
      setUrl(res?.data?.data?.url);
    })
  }, [getData])

  return (
    <>
      {
        (getData !== null && getData !== undefined) && (
              <div className='popup mainbanner event-banner'>
                <div className="close"></div>
                <div className="img-wrap">
                  {
                    url ? (
                        <a href={url} target="_blank">
                          <img src={getData} alt="" />
                        </a>
                    ) : (
                        <img src={getData} alt="" />
                    )
                  }
                </div>
                <div className="popup-btn-wrap">
                  <div className="popup-btn" onClick={onCheck}>
                    {selCheck
                        ? 'Checked'
                        : '다시 보지 않기'
                    }
                  </div>
                  <div className="popup-btn" onClick={onClosePopUp}>닫기</div>
                </div>
              </div>
          )
      }
    </>
  )
}

export default MainBanner