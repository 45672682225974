import axios from "axios";
import qs from "qs";

const axiosInstance = axios.create({
    // baseURL: ``,
    baseURL: `${process.env.REACT_APP_BASE_API}`,
    headers: {
        "Content-Type": "application/json",
    },
    paramsSerializer: (params:any) => qs.stringify(params),
});

axiosInstance.interceptors.response.use(
    async (response) => {
        return response?.data;
    },
    async (error) => {
        // Handle errors
        const status = error.status || error.response.status;
        throw error;
    }
);

export default axiosInstance;