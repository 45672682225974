import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
// import './assets/styles/global.scss';
import Main from './pages/Main/Main';
import NavLinks from './common/gnbitem/NavLinks';
import Story from './pages/story/index';
import HowToUse from './components/howto/HowToUse';
import Notice from './pages/nowbancow';
import Detail from './pages/nowbancow/detail';
import Event from './pages/inquiry';
import GNB from './common/global/GNB';
import Qna from './pages/howto/qna';
import Home from './pages';
import RouteChangeTracker from './components/ga/RouteChangeTracker';
import Regist from './pages/inquiry/regist';
import FarmDetail from './pages/Main/FarmDetail';
import HowToUseDetail from './components/howto/HowToUseDetail';
import useDeviceDetect from './common/util/useDeviceDetect';
import Footer from './common/global/Footer';
import NotFound404 from './pages/error/NotFound404';
import Terms from './pages/terms/terms';
import Popup from './components/popup/Popup';
import MainBanner from './components/popup/MainBanner';
// import { withCookies, Cookies, ReactCookieProps } from 'react-cookie';
import { useCookies, ReactCookieProps } from "react-cookie";
import moment from 'moment';
import { useAnalytices } from './hooks/useAnalytics';
import { GaWrapper } from './components/ga/GaWrapper';
// import Nav from './pages/Nav';

interface MainProps extends ReactCookieProps { }

function setScreenSize() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setScreenSize();
// setMedia();
window.addEventListener('resize', () => setScreenSize());

function App(props: MainProps) {
  const [popupState, setPopupState] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['popupState']);
  let now = new Date;
  let after1m = new Date();

  useEffect(() => {
    if (cookies.popupState !== undefined) {
      setPopupState(true);
    }
  }, []);


  RouteChangeTracker();
  const { isMobile } = useDeviceDetect();
  const location = useLocation();
  const [footerState, setFooterState] = useState(''); // footer 다크모드 유무
  const [globalState, setGlobalState] = useState(''); // 헤더, footer 둘다 유무
  const [mainPopup, setMainPopup] = useState('');

  useEffect(() => {
    location.pathname === '/story' ?
      setFooterState('dark') :
      setFooterState('')
    location.pathname.indexOf('/terms') === 0 ?
      setGlobalState('false') :
      setGlobalState('')
    location.pathname === '/' ?
      setMainPopup('true') :
      setMainPopup('')
  }, [location])

  const cookieState = (cookieCheck: any): void => {
    if (cookieCheck) {
      after1m.setDate(now.getDate() +1);
      after1m.setHours(0o0);
      after1m.setMinutes(0o0);
      after1m.setSeconds(0o0);
      // after1m.setMinutes(now.getMinutes() +1);
      // console.log('after1m : ', after1m);
      setCookie('popupState', true, { path: '/', expires: after1m });
    } else {
      removeCookie('popupState');
    }
  }

  const closePopUp = (selCheck: boolean): void => {
    setPopupState(selCheck);
  }

  const { initialized } = useAnalytices();

  return (
    <>
      {
        globalState === 'false' ? <></> :
          <GNB />
      }
      <GaWrapper intilalized={initialized}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/story" element={<Story />} />
          <Route path="/nowbancow" element={<Notice />} />
          <Route path="/nowbancow/*" element={<Detail />} />
          <Route path="/notice" element={<HowToUse />} />
          {
            isMobile &&
            <Route path="/notice/*" element={<HowToUseDetail faqId={undefined} />} />
          }
          <Route path="/inquiry" element={<Event />} />
          <Route path="/howto/qna" element={<Qna />} />
          <Route path="/inquiry/regist" element={<Regist />} />

          <Route path="/farm/:raiserId" element={<FarmDetail />} />

          <Route path="/*" element={<NotFound404 />} />

          <Route path="/terms/:termsCode" element={<Terms />} />
        </Routes>
      </GaWrapper>
      {
        globalState === 'false' ? <></> :
          <Footer themeType={footerState} />
      }
      <div id="modal-root">
        {
          (popupState && cookies.popupState === undefined && mainPopup) ?
            <Popup>
              <MainBanner closePopUp={closePopUp} cookieState={cookieState} />
            </Popup> : <></>
        }
      </div>
    </>
  );
}

export default App;

