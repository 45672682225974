import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextArrow from '../Slide/NextArrow';
import PrevArrow from '../Slide/PrevArrow';
import { Container, Radius30WhiteBox } from '../../common/layouts';
import styled from 'styled-components';
import classNames from 'classnames';
import { MainPageTitle } from '../../common/typography';
import axios from 'axios';
import { themeDefault } from '../../themes/default';
import { useAdministrativeDivision, useRaiserList } from "../../hooks/useRaiser";
import { Link } from "react-router-dom";
import Loading from 'src/components/Loading/Loading';
import { useLocation } from "react-router";

const FarmSlide = () => {
  const [tabindex, setTabindex] = useState(0);
  const baseApi = process.env.NEXT_PUBLIC_BASE_API;
  const [getData, setGetData] = useState([]);

  const [raiserList, setRaiserList] = useState<any[]>([]);

  const { isLoading, isError, isFetching, data } = useAdministrativeDivision();

  const [raiserRequest, setRaiserRequest] = useState({
    search_page: 1,
    search_count: 25,
    administrative_division_code: '',
  });

  const fetchRaiserList = () => {
    axios.get('https://prod.stockeeper.in/bancow/api/v1/raiser/list', {
      params: raiserRequest
    })
      .then(result => {
        if (result?.data?.success)
          setRaiserList(result?.data?.response?.raiserList);
      })
    // .catch(error => console.log("error:", JSON.stringify(error)));
  }

  // const handleClick = (raiserId: string) => {
  //   console.log("raiserId", raiserId);
  // }

  useEffect(() => {
    fetchRaiserList();

    return () => {
      setRaiserList([]);
    }
  }, [raiserRequest])

  const slideClick = (index: number) => {
    setTabindex(index);
    setRaiserRequest({
      ...raiserRequest,
      // @ts-ignore
      administrative_division_code: index === 0 ? '' : data?.data?.response[index - 1].codeId // locationList[index].codeId
    });
  }

  // 옵션
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]

    // dots: true,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 4,
    // slidesToScroll: 4,
    // initialSlide: 0,
  };

  return data?.data?.response ? (
    <FarmSlideStyle>
      <MainPageTitle>
        뱅카우와 함께하는 농장
      </MainPageTitle>
      <Tab>
        {
          isFetching ? (
            <Loading />
          ) : (
            <>
              <div
                className={classNames('tab-nav', { 'is-select': tabindex === 0 })}
                onClick={() => slideClick(0)}
              >
                {'전체'}
              </div>
              {
                data?.data?.response.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames('tab-nav', { 'is-select': tabindex === index + 1 })}
                      onClick={() => slideClick(index + 1)}
                    >
                      {(item.codeNm === '충청' || item.codeNm === '제주') ? '' : item.codeNm}
                    </div>
                  )
                })
              }
            </>
          )
        }
      </Tab>
      {
        raiserList.length < 2 ?
          <>
            {
              raiserList.map((item, index) => {
                return (
                  <Card className='card one-card' key={`${index}-${index}`}>
                    <Link to={{ pathname: `/farm/${item.raiserId}` }}>
                      <div className="imgwrap">
                        <img src={`${item.fileInfoList[0]?.image_file_url}`} />
                      </div>
                      <div className='text'>
                        <p>{item.slogan}</p>
                        <strong>{item.raiserCareerYear}년</strong>
                        <span>|</span>
                        <strong>{item.raiserName}</strong> {item.farmAddress}
                      </div>
                    </Link>
                  </Card>
                )
              })
            }
          </> :
          <Slider {...settings} className="home-slide">
            {
              raiserList.map((item, index) => {
                return (
                  <Card className='card' key={`${index}-${index}`}>
                    <Link to={{ pathname: `/farm/${item.raiserId}` }}>
                      <div className="imgwrap">
                        <img src={`${item.fileInfoList[0]?.image_file_url}`} />
                      </div>
                      <div className='text'>
                        <p>{item.slogan}</p>
                        <strong>{item.raiserCareerYear}년</strong>
                        <span>|</span>
                        <strong>{item.raiserName}</strong> {item.farmAddress}
                      </div>
                    </Link>
                  </Card>
                )
              })
            }
          </Slider>
      }
    </FarmSlideStyle>
  ) : null;
}

const FarmSlideStyle = styled(Container)`
  margin-top: 300px;
  height: 493px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 120px;
    height: 475px;
    padding: 0 20px;
    .slick-arrow {
      display: none!important;
    }
  }
  ${MainPageTitle} {
    @media ${themeDefault.breakpoints.sm} {
      font-size: 28px;
    }
  }
  .imgwrap {
    position: relative;
    height: 257px;
    background: #eee;
    overflow: hidden;
    @media ${themeDefault.breakpoints.sm} {
      height: 250px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slick-slider {
    margin-top: 35px;
    @media ${themeDefault.breakpoints.sm} {
      div[class*="slideArrow"] {
        display: none;
      }
    }
  }
  .slick-slide {
    // width: 436px!important;
    .card {
      opacity: 0.8;
    }
  }
  .slick-list {
    // padding: 0px 60px 0 145px!important;
    padding: 0px 60px 0px 85px!important;
    @media (max-width: 1020px) {
      padding: inherit!important;
    }
    @media (max-width: 768px) {
      // padding: 0px 60px 0px 60px!important;
      padding: inherit!important;
    }
  }
  .slick-active.slick-cloned, .slick-center {
    .card {
      opacity: 1;
    }
  }
`

const Tab = styled.div`
  display: flex;
  margin-top: 33px;
  .tab-nav {
    color: #999999;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 30px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 14px;
    }
    &:last-child {
      margin-right: 0;
    }
    &.is-select {
      position: relative;
      color: #333;
      font-weight: 700;
      &:after {
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 10vw;
        background: black;
        left: 0;
        bottom: -5px;
        content: '';
      }
    }
  }
`

const Card = styled(Radius30WhiteBox)`
  margin: 10px 0;
  width: 400px!important;
  height: 342px;
  overflow: hidden;
  @media ${themeDefault.breakpoints.sm} {
    width: 100%!important;
    height: 342px;
  }
  &.one-card {
    margin: 40px auto 0 auto;
  }
  p {
    margin-top: 13px;
    margin-bottom: 13px;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 16px;
    }
  }
  .text {
    margin: 0 16px;
    color: #999999;
    font-weight: 500;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 14px;
    }
  }
  span {
    margin: 0 7px;
  }
  strong {
    color: #000;
  }

`

export default FarmSlide
