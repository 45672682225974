import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

type AlignType = {
  left?: string;
  right?: string;
  center?: string;
}

type ItemType = {
  listLength?: number;
  activeStep?: number;
  pagingCount?: number; // 총 갯수
  viewCount?: number; // 몇개씩 보여줄지
  getPaging?: (value: number) => void;
  // align?: Array<AlignType>;
  align?: string;
}



const Pagenation = ({
  activeStep = 0,
  pagingCount = 0,
  viewCount = 0,
  getPaging = (value: number) => null,
  align
}: ItemType
) => {

  const clickStep = (value: number) => {
    getPaging(value);
  }

  return (
    <PagenationStyle align={align} className="pagenation">
      <ul>
        {[...Array(pagingCount)].map((n, index) => {
          return (
            <li className={classNames('', { 'is-active': activeStep === index })} key={index}
              onClick={() => clickStep(index)}
            >
              {index + 1}
            </li>
          )
        })}
      </ul>
    </PagenationStyle>
  )
}

const PagenationStyle = styled.div<{ align?: string }>`
  ul {
    display: flex;
    justify-content: ${props => props.align || 'center'};
    li {
      display: inline-flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      &:before {
        content: none!important;
      }
      &.is-active {
        color: #004BFA;
        font-weight: 700;
      }
    }
  }
`

export default Pagenation