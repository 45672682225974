import { useEffect, useState } from 'react';
import ReactGa from 'react-ga';

export function useAnalytices() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if(window.location.href.includes('localhost')) {
      ReactGa.initialize('UA-222889015-1')
    }
    setInitialized(true);
  },[]);

  return {
    initialized
  }
}
