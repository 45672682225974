import styled from 'styled-components';
import { themeDefault } from '../../themes/default';
import AppleApp from '../AppleApp';
import GoogleApp from '../GoogleApp';
import './global.scss';

function AppLinkBox() {
	return (
		<div className='item-box'>
			<GoogleApp />
			<AppleApp />
		</div>
	);
}

export default AppLinkBox;
