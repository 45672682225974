import axios, {AxiosResponse} from "axios";
import {eventDetailResponse, eventListResponse, eventPageRequest, eventProp} from "../types";
import {useMutation, useQuery, UseQueryResult} from "react-query";

type ReturnType = AxiosResponse<eventListResponse>;

type DetailReturnType = AxiosResponse<eventDetailResponse>;

const apiUrl = process.env.REACT_APP_BASE_API;

// event get PostMapping 변경 필요
export const eventApi = (prop: eventPageRequest) => axios.post(`${apiUrl}event/public/list`, prop);

export const useEventList = (prop: eventPageRequest): UseQueryResult<ReturnType, Error> => {
    return useQuery(['eventList'], () => eventApi(prop), {enabled: true, cacheTime: 0});
}

const eventDetailApi = (eventId: number) => axios.get(`${apiUrl}event/public/detail/${eventId}`);

export const useEventDetail = (eventId: number): UseQueryResult<DetailReturnType, Error> => {
    return useQuery(['eventDetail'], () => eventDetailApi(eventId), {
        refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
        retry: 1, // 실패시 재호출 몇번 할지
    });
}
