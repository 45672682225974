import React, {ChangeEventHandler, CSSProperties, HTMLInputTypeAttribute} from 'react'
import {FieldProps, getIn} from "formik";
import classNames from "classnames";

interface TextAreaProps extends FieldProps {
  id?: string|undefined,
  label?: any;
  disabled?: boolean|undefined;
  className?: string|undefined;
  inputChange?: ChangeEventHandler<any> | undefined;
  style?: CSSProperties|undefined;
  inputProps?: any|undefined;
  placeholder?: any;
  type?: HTMLInputTypeAttribute|undefined;
  required?: boolean|undefined;
}

const TextArea = (props: TextAreaProps) => {
  const {
    field,
    form,
    id,
    label,
    disabled,
    style,
    inputChange,
    className,
    inputProps,
    placeholder,
    required,
  } = props;
  const { name, value = '',  ...rest } = field;
  const { errors, touched, isValid, handleBlur } = form;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  let showError = error && touch;

  const handleChangeInput = (event: any) => {
    event.persist();

    const changeEvent:any = {
      target: {
        name: name,
        value: event.target.value
      }
    };
    field.onChange(changeEvent);

    inputChange && inputChange(changeEvent);
  };

  return (
      <div className={classNames(["inputwrap", {
        'is-false': showError,
      }])}>
        <label htmlFor={id}>
          <strong>{label}</strong>
          <textarea
              name={name}
              id={id}
              onChange={handleChangeInput} placeholder={placeholder}
              required={required}
              onBlur={handleBlur}
              disabled={disabled}
              value={value}
          />
        </label>
        <div className="false-code">
          {error}
        </div>
      </div>
  )
}

export default TextArea