import React from 'react'
import styled, { keyframes } from 'styled-components';

type cssslideType = {
  children?: any;
  timer?: number;
  width?: number;
  className?: string;
}

const CssSlide = ({ children, timer = 10, width, className }: cssslideType) => {
  return (
    <Slider className={className}>
      <Track>
        {children}
      </Track>
    </Slider>
  )
}

const Track = styled.div`
`

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc((-360px * 6) + (-24px * 6)))}
`

const Slider = styled.div`
  width: 100%;
  overflow:hidden;
  position: relative;
  ${Track} {
    animation: ${scroll} 20s linear infinite;
    display: flex;
    width: calc( (360px * 12) + (24px * 12) );
    gap: 24px;
    &:hover {
      animation-play-state: paused;
  }
`



export default CssSlide