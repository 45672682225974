import axios from "axios";
import axiosInstance from "./axiosInstance";

const request_token = process.env.REACT_APP_INSERT_TOKEN;
const apiUrl = process.env.REACT_APP_BASE_API;

export const getInsertToken = async () => {
    try {
        const { data }: any = await axiosInstance.get(`${apiUrl}qna/token?client=${request_token}`);
        if (data?.result)
            return data?.message;
        else
            return "TK01";
    } catch (error) {
        return 'TK01';
    }
}