import Footer from '../common/global/Footer';
import SubFooter from '../common/global/SubFooter';

import Slogan from '../components/home/Slogan';
import Catchphrase from '../components/home/Catchphrase';
import IntroService from '../components/home/IntroService';
import ExplainBuy from '../components/home/ExplainBuy';
import ExplainGrow from '../components/home/ExplainGrow';
import ExplainMarket from '../components/home/ExplainMarket';
import ExplainSell from '../components/home/ExplainSell';
import CowChart from '../components/home/CowChart';
import SubSlogan from '../components/home/SubSlogan';
import Discription from '../components/home/Discription';
import FarmSlide from '../components/home/FarmSlide';
import Tutorial from '../components/home/Tutorial';
import Partner from '../components/home/Partner';
import Review from '../components/home/components/Review';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface review {
  id: number;
  content: string;
  title: string;
  buyer_name: string;
  farm_name: string;
  farm_ceo_name: string;
  farm_image: string;
}
export interface info {
  category: string;
  info: [];
}

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    // location.pathname === '/' && window.scrollTo(300, 0);
    let vh = window.innerHeight;
    // console.log('vh : ', vh);

    localStorage.getItem('location') === 'true' ? window.scrollTo(0, vh + 3100) : window.scrollTo(0, 0); 
    setTimeout(() => {
      localStorage.removeItem('location');
    }, 100);
    // window.scrollTo(0, vh + 3100)
  }, [window.innerHeight])

  return (
    <>
      <Slogan />
      <SubSlogan />
      <Discription />
      <FarmSlide />
      <Tutorial />
      <Partner />
      <Review />
    </>
  );
};

export default Home;
