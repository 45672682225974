import React, { useEffect } from 'react';
import ReactGa from 'react-ga';
import { useLocation } from 'react-router-dom';

interface WrapperProps {
  intilalized: boolean;
  children: React.PropsWithChildren<any>;
}

export function GaWrapper(props: WrapperProps) {
  const location = useLocation();

  useEffect(() => {
    if(props.intilalized) {
      ReactGa.pageview(location.pathname + location.search);
    }
  }, [props.intilalized, location]);

  return props.children;
}