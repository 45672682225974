import React from "react";
// import styles from './slideArrow.module.scss';
import './slideArrow.scss';

interface PrevArrowProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function PrevArrow({ onClick }: PrevArrowProps) {
  return <div className="slideBtn is_left" onClick={onClick}></div>;
}