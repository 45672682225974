import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    window.addEventListener('resize', function(event) {
      if( window.innerWidth <= 767 || mobile) {
        setMobile(true);    
      }
      else {
        setMobile(false);    
      }
    }, true);

    if( window.innerWidth <= 767 || mobile) {
      setMobile(true);    
    }
    else {
      setMobile(false);    
    }
  }, []);


  return { isMobile };
}