import React from 'react'
import styles from './history.module.scss';

const History2022 = () => {
  return (
    <div className={styles.story}>
      <strong>2022</strong>
      <ul>
        <li>
          <span>08</span>KB STAR 기업 선정
        </li>
        <li>
          <span>07</span>소비자 보호 및 관리 체계 구축 MOU 협약
        </li>
        <li>
          <span>06</span>
          KB 공급망 사업 계약 체결<br />
          서울핀테크랩 보육기업 협약<br />
          마이스터고 산학협력 MOU 체결
        </li>
        <li>
          <span>05</span>서울핀테크랩 보육기업 협약 (K-accelerator)
        </li>
        <li>
          <span>04</span>글로벌 농축산 사료기업 Cargill Purina MOU 체결
        </li>
        <li>
          <span>03</span>농가 입점 및 관리 시스템 기술임치 (Tech Safe)
        </li>
        <li>
          <span>02</span>뱅카우 한우 상생농가 500두 돌파
          <br />가축투자플랫폼 기술보증 확대 (기술보증기금)
        </li>
      </ul>
    </div>
  )
}

export default History2022