import React from 'react'
import styled from 'styled-components'
import { BackgroundImg, Container } from '../../common/layouts'
import { MainPageSubTitle } from '../../common/typography'
import { themeDefault } from '../../themes/default';
import styles from './homepage.module.scss';

const Partner = () => {
  return (
    <PartnerStyle>
      <MainPageSubTitle>
        Partners
        <div className={styles.partner}></div>
      </MainPageSubTitle>
      <MainPageSubTitle className="two">
        Investors
        <div className={styles.investors}></div>
      </MainPageSubTitle>
    </PartnerStyle>
  )
}

const PartnerStyle = styled(Container)`
  margin-top: 341px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 156px;
    padding: 0 20px;
  }
  .two {
    margin-top: 119px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 50px;
    }
  }
`

const Img = styled(BackgroundImg)`
  margin-top: 39px;
  height: 255px;
  @media ${themeDefault.breakpoints.sm} {
    width: 100%;
    height: 285px;
  }
`

const Img2 = styled(BackgroundImg)`
  margin-top: 39px;
  width: 960.75px;
  height: 50px;
  @media ${themeDefault.breakpoints.sm} {
    width: 100%;
    height: 73px;
  }
`

export default Partner