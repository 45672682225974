import { Dispatch, SetStateAction } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import NavLinks from './NavLinks';
import styled from 'styled-components';
import { themeDefault } from '../../themes/default';

interface NavMenuAreaProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  color?: string;
}

const NavMenuArea: React.FC<NavMenuAreaProps> = ({ setIsOpen, color }) => {
  return (
    <MenuArea>
      {/* <ModeToggle /> */}
      <NavLinks color={color} />
      <GiHamburgerMenu
        onClick={() => setIsOpen(true)}
        style={{
          color: color ? color : 'black',
          fontSize: '28px',
          marginTop: '2px'
        }}
      />
    </MenuArea>
  );
};

const MenuArea = styled.div`
	display: flex;
	gap: 34px;
	svg {
		display: none;
	}
	@media ${themeDefault.breakpoints.sm} {
		gap: 8px;
		a {
			display: none;
		}
		svg {
			display: block;
		}
	}
`;

export default NavMenuArea;
