import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themeDefault } from '../../themes/default';
import BancowTypoLogo from '../gnbitem/BancowTypoLogo';
import MobileMenu from '../gnbitem/MobileMenu';
import NavMenuArea from '../gnbitem/NavMenuArea';
import { RowSpaceBetween } from '../layouts';
import { useLocation } from 'react-router-dom';

const GNB = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [headerState, setHeaderState] = useState('');
  const [pageState, setPageState] = useState('');
  const [ScrollY, setScrollY] = useState(0);  // 스크롤값을 저장하기 위한 상태
  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  }

  useEffect(() => {
    // console.log("ScrollY is ", ScrollY); // ScrollY가 변화할때마다 값을 콘솔에 출력
    if (
      location.pathname === '/story' ||
      location.pathname === '/'
    ) {
      if (window.innerWidth > 768) {
        if (ScrollY > 700) {
          setHeaderState('');
        }
        else {
          setHeaderState(location.pathname)
        }
      }
      else {
        if (ScrollY > 400) {
          setHeaderState('');
        }
        else {
          setHeaderState(location.pathname)
        }
      }
    } else { setHeaderState('') }
    if (
      location.pathname === '/inquiry' ||
      location.pathname === '/notice'
    ) {
      setPageState('none');
    }
  }, [location, ScrollY]);


  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  })

  return (
    <NavBox className={classNames('', {
      'is-white': headerState !== '',
      'bg-none': pageState === 'none'
    })}>
      <Nav as="nav">
        <Link to="/">
          <BancowTypoLogo color={headerState !== '' ? '#000' : '#004BFA'} />
        </Link>
        <NavMenuArea setIsOpen={setIsOpen} color={headerState !== '' ? '#000' : ''} />
        <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </Nav>
    </NavBox>
  )
}

const NavBox = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
	background: ${themeDefault.alt.backgroud5};
  z-index: 10;
  + div {
    padding-top: 68px;
  }
  &.bg-none {
    // background: transparent;
  }
  &.is-white {
    // position: relative;
    background: transparent;
    + div {
      // margin-top: -68px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 0;
      }
    }
  }
`;

const Nav = styled(RowSpaceBetween)`
	width: 82%;
	height: 60px;
	@media ${themeDefault.breakpoints.sm} {
		width: 88%;
	}
`;

export default GNB