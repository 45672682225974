import {useEffect, useState} from "react";
import Util from "../../common/util/Util";
import classNames from "classnames";
type RichTextContentProps = {
    content: string|undefined;
    className?: string;
}
export default function RichTextContent(props:RichTextContentProps) {
    const { content = '', className } = props;
    const [html, setHtml] = useState(content);
    useEffect(() => {
        if (content) {
            setHtml(Util.replaceEmbedContents(content));
        }
    }, [content])
    return (
        <div className={classNames(['ck-content', className])} dangerouslySetInnerHTML={{ __html: html }} />
    )
}