import React, { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames';
import { Container, Radius30WhiteBox } from '../../../common/layouts'
import { MainPageTitle } from '../../../common/typography'
import styles from './toggleView.module.scss';
import AreaChartC from '../../chart/AreaChartC';
import useDeviceDetect from '../../../common/util/useDeviceDetect';
import { themeDefault } from '../../../themes/default';

const GrowthChart = () => {
  const { isMobile } = useDeviceDetect();
  const [isOpen, setIsOpen] = useState(false);

  const toggleView = () => {
    setIsOpen(!isOpen)
  }
  return (
    <GrowthChartStyle>
      {
        <MainPageTitle>
          No! <br />한우 경매가는 <br className='m-layer'/> 꾸준히 성장하고 있어요
        </MainPageTitle>
      }
      {
        isMobile &&
        <Card className='' style={{ marginTop: '28px'}}>
          10년 전보다
          <span>+81.2%</span>
        </Card>
      }

      <ShowChart className={classnames('is-open')}>
        {
          <div className='toggle-chart'>
            <div className="top-desc chart-desc">평균가격(두, 한우 거세우)</div>
            <AreaChartC />
            <div className="bottom-desc chart-desc">출처: 축산물품질평가원 및 통계청</div>
          </div>
        }
        {/* <div className="toggle-wrap">
          <Toggle onClick={toggleView} className={`${styles.toggle_view}`}>10년 그래프 {isOpen === true ? '닫기' : '펼쳐보기'}</Toggle>
        </div> */}
      </ShowChart>
    </GrowthChartStyle>
  )
}

const Card = styled(Radius30WhiteBox)`
  display: flex;
  width: 500px;
  height: 192px;
  padding: 0 20px;
  font-size: 20px;
  color: #999;
  align-items: center;
  justify-content: space-between;
  @media ${themeDefault.breakpoints.sm} {
    width: calc(100% - 40px);
    height: 120px;
    margin: 0 auto;
    font-size: 16px;
  }
  span {
    font-weight: 700;
    color: #333;
    font-size: 80px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 36px;
    }
  }
`

const GrowthChartStyle = styled(Container)`
  ${MainPageTitle} {
    line-height: 54px;
    @media ${themeDefault.breakpoints.sm} {
      padding-left: 20px;
      font-size: 28px;
      line-height: 33.4px;
    }
  }

`

const Toggle = styled.div`
`

const ShowChart = styled.div`
margin-top: 20px;
@media ${themeDefault.breakpoints.sm} {
}
.toggle-wrap {
  position: relative;
  display: flex;
  justify-content: end;
  positoin: relative;
  margin-top: 100px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 50px;
  }
}
.toggle-chart {
  display: none;
  .chart-desc {
    text-align: right;
    color: #999;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 10px;
      margin-right: 20px;
    }
  }
  .bottom-desc {
    margin-top: 16px;
  }
}
&.is-open {
  .toggle-wrap {
    margin-top: 60px;
  }
  .toggle-chart {
    display: block;
  }
  ${Toggle} {
    &:after {
      transform: rotate(180deg);
    }
  }
}
`

export default GrowthChart