import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BackgroundImg, Flex } from '../layouts';
import classNames from 'classnames';
import './footerItem.scss';
import { themeDefault } from '../../themes/default';


type footerType = {
  themeType?: string;
}

const CompanyInfo = ({ themeType }: footerType) => {
  return (
    <div>
      <InfoBox themeType={themeType}>
        <dl>
          <dt>브랜드 리소스</dt>
          {/* <dd className={cx('tail', themeType === 'dark' ? 'is-dark' : '')}> */}
          <dd className={classNames('tail',
            themeType === 'dark' ? 'is-dark' : ''
          )}>
            <a href="/Brand_guideline_bancow_ver01.pdf" download target="_blank"> 브랜드 가이드 </a>
          </dd>
        </dl>
        <dl>
          <dt>이용약관</dt>
          <dd className={classNames('tail',
            themeType === 'dark' ? 'is-dark' : ''
          )}>
            <Link to="/terms/C024001" target="_blank" rel="noopener noreferrer" > 서비스 이용약관 </Link>
          </dd>
          <dd className={classNames('tail',
            themeType === 'dark' ? 'is-dark' : ''
          )}>
            <Link to="/terms/C024002" target="_blank"> 개인정보 처리방침 </Link>
          </dd>
          <dd className={classNames('tail',
            themeType === 'dark' ? 'is-dark' : ''
          )}>
            <Link to="/terms/C024004" target="_blank"> 구매회원 이용약관 </Link>
          </dd>
          <dd className={classNames('tail',
            themeType === 'dark' ? 'is-dark' : ''
          )}>
            <Link to="/terms/C024003" target="_blank"> 선택약관 </Link>
          </dd>
        </dl>
        <dl className='customer-service'>
          <dt>고객센터</dt>
          <dd>전화: 02-2274-2517 (평일 10시~ 17시)</dd>
          <dd>이메일: <a href="mailto:bankcow@stockeeper.co">bankcow@stockeeper.co</a></dd>
        </dl>
      </InfoBox>
    </div>
  );
};


const InfoBox = styled(Flex) <footerType>`
  font-size: 15px;
  gap: 28px;
  @media ${themeDefault.breakpoints.sm} {
    font-size: 12px;
    gap: 19px;
  }
  a {
    ${props => props.themeType === 'dark'
    ?
    `
      background: black;
      color: white;
		`
    :
    `
    `
  }
  }
  dt {
    font-weight: 700;
    margin-bottom: 21px;
    @media ${themeDefault.breakpoints.sm} {
      margin-bottom: 6px;
    }
  }

  dd {
    margin-bottom: 15px;
    @media ${themeDefault.breakpoints.sm} {
      margin-bottom: 3px;
      line-height: 1.4;
    }
  }
  .customer-service {
    margin-left: 100px;
    @media ${themeDefault.breakpoints.sm} {
      margin-left: 0px;
    }
  }
`;


export default CompanyInfo;
