import React, {ChangeEventHandler, CSSProperties, HTMLInputTypeAttribute, useState} from 'react'
import {FieldProps, getIn} from "formik";
import classNames from "classnames";

interface SelectProps extends FieldProps {
  id?: string|undefined,
  label?: any;
  disabled?: boolean|undefined;
  className?: string|undefined;
  inputChange?: ChangeEventHandler<any> | undefined;
  style?: CSSProperties|undefined;
  inputProps?: any|undefined;
  placeholder?: any;
  type?: HTMLInputTypeAttribute|undefined;
  required?: boolean|undefined;
  data: any[],
  valueKey?: string,
  labelKey?: string,
}

const Select = (props: SelectProps) => {
  const {
    field,
    form,
    id,
    label,
    disabled,
    style,
    inputChange,
    className,
    inputProps,
    placeholder,
    type = 'text',
    required,
    data,
    valueKey = 'value',
    labelKey = 'label',
  } = props;
  const { name, value = '',  ...rest } = field;
  const { errors, touched, isValid, handleBlur, setTouched } = form;
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  let showError = error && touch;

  const [view, setView] = React.useState(false);
  const [clickList, setClickList] = useState(false);

  const handleChangeInput = (item: any) => {

    const changeEvent:any = {
      target: {
        name: name,
        value: item[valueKey]
      }
    };
    field.onChange(changeEvent);

    inputChange && inputChange(changeEvent);
    setClickList(true);
    setView(false);
  };

  const OpenClick = () => {
    setView(!view);
    if (!view) {
      handleBlur(name);
      setTouched({...touched, [name]: true});
    }
  }

  const getLabel = (value: any) => {
    return data.find((item: any) => item[valueKey] === value)?.[labelKey];
  }

  return (
      <div className={classNames(["inputwrap", {
        'is-false': showError,
      }])}>
        <label htmlFor={id}>
          <strong>{label}</strong>
          <div className={classNames('DropDown-area')}>
            <strong onClick={OpenClick} className={classNames("", { 'is-selected': clickList })}>
              { value ? getLabel(value) : placeholder }
            </strong>
            {
                view &&
                <ul>
                  {
                      data && data.map((item, key) => {
                        return (
                            <li
                                key={key}
                                className={classNames('', { 'is-select': value === item[valueKey] })}
                                // className={classNames('')}
                                onClick={() => handleChangeInput(item)}
                            >
                              {item[labelKey]}
                            </li>
                        )
                      })
                  }

                </ul>
            }
          </div>
        </label>
        <div className="false-code">
          {error}
        </div>
      </div>
  )
}

export default Select