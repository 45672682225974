import classNames from 'classnames';
import React, { useEffect } from 'react'
import styled from 'styled-components';
import styles from './steps.module.scss';

type ItemType = {
  listLength?: number;
  activeStep?: number;
  ClickStep?: (value: number) => void;
}

const Steps = ({ listLength = 3, activeStep = 0, ClickStep = (value: number) => 0 }: ItemType) => {
  return (
    <div className={styles.steps_area}>
      {[...Array(listLength)].map((n, index) => {
        return (
          <StepsItem className={classNames(styles.steps_item,
            { 'is-active': activeStep === index },
            { 'event-none': activeStep !== index + 1}
          )}
            key={index}
            onClick={() => ClickStep(index)}
          >
            {index + 1}
          </StepsItem>
        )
      })}
    </div>
  )
}

const StepsItem = styled.div`
  &.is-active { 
    background: #004BFA!important;
  }
`

export default Steps;