import BrowserAppleApp from '../assets/icon/BrowserAppleApp';
import MobileAppleApp from '../assets/icon/MobileAppleApp';
import { DownloadAppIconBox } from './layouts';
import styles from './AppStyle.module.scss';

function AppleApp() {
  return (
    <a
      data-testid="linkElement"
      href="https://apps.apple.com/us/app/%EB%B1%85%EC%B9%B4%EC%9A%B0/id1588858780"
      target="_blank"
      rel="noreferrer noopener"
    >
      <DownloadAppIconBox>
        <div className={`${styles.appleapp} ${styles.app_img}`}></div>
      </DownloadAppIconBox>
    </a>
  );
}

export default AppleApp;
