import React from 'react';
import styled from 'styled-components';
import { Container } from '../../common/layouts';
import { MainPageTitle } from '../../common/typography';
import History2022 from '../../components/story/History2022';
import { themeDefault } from '../../themes/default'
import History2021 from '../../components/story/History2021';
import History2020 from '../../components/story/History2020';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import notice_bg from './notice_bg.png';
import notice_bg1 from './notice_bg1.png';
import notice_bg2 from './notice_bg2.png';
import notice_bg3 from './notice_bg3.png';
import styles from './story.module.scss';
import './story.scss';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

const Story = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 4500,
    cssEase: "linear",
    fade: true,
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  })

  return (
    <>
      <StoryContainer>
        <div className="key_slide">
          <Slider {...settings} >
            <div>
              <div className="imgwrap">
                <img src={notice_bg} alt="" />
                <p>스탁키퍼는 생산자와 소비자를 이어 <br />쉬운 축산, 재미있는 축산 생태계를<br /> 함께 만들어갑니다.</p>
              </div>
            </div>
            <div>
              <div className="imgwrap">
                <img src={notice_bg1} alt="" />
                <p>스탁키퍼는 생산자와 소비자를 이어 <br />쉬운 축산, 재미있는 축산 생태계를<br /> 함께 만들어갑니다.</p>
              </div>
            </div>
            <div>
              <div className="imgwrap">
                <img src={notice_bg2} alt="" />
                <p>스탁키퍼는 생산자와 소비자를 이어 <br />쉬운 축산, 재미있는 축산 생태계를<br /> 함께 만들어갑니다.</p>
              </div>
            </div>
            <div>
              <div className="imgwrap">
                <img src={notice_bg3} alt="" />
                <p>스탁키퍼는 생산자와 소비자를 이어 <br />쉬운 축산, 재미있는 축산 생태계를<br /> 함께 만들어갑니다.</p>
              </div>
            </div>
          </Slider>
        </div>

        <div className={styles.ment_area}>
          <strong>건강한 축산 생태계 구축을 위한 한걸음</strong>
          <p>크고 광활한 축산이라는 생태계에서 <br className="m-layer" />스탁키퍼는 한걸음씩 내딛습니다. <br className='' />사육의 변화, 유통의 변화, 가격의 변화 등 <br className="m-layer" />작은 발걸음들을 걸어갑니다. <br />22조가 넘는 이 시장의 혁신을 <br className="m-layer" />함부로 말하지 않습니다. <br />스탁키퍼와 고객이 함께 걷는 작은 발걸음들로 <br className="m-layer" />이 시장을 채워나갑니다.</p>
        </div>

        <div className={styles.visual_card}>
          <MainPageTitle>스탁키퍼의 서비스 </MainPageTitle>
          <ul>
            <li className={styles.second_card}>
              <div className={styles.textwrap}>
                <strong>송아지 키우며 자산을 늘려요 <br />뱅카우</strong>
                <p>한우펀딩플랫폼 뱅카우는 농장과 소비자를 펀딩으로 연결합니다. 뱅카우 펀딩을 통해 농가는 생산성을 높이고 참여자는 한우펀딩의 기회를 갖는 WIN-WIN의 생태계를 만들어갑니다.</p>
              </div>
              <div className={styles.imgwrap} />
            </li>
            <li className={styles.first_card}>
              <div className={styles.textwrap}>
                <strong>매일 프리미엄하게, <br />고메 프리미엄</strong>
                <p>고객의 식탁에 프리미엄을 선사합니다. 세계 곳곳의 최고급 육류만을 선별하여 안전하고 품질 높은 식품으로 만족도를 제공합니다. 건강한 식품화를 이끌어가는 기업이 되기 위해 최선을 다하고 있습니다.</p>
              </div>
              <div className={styles.imgwrap} />
            </li>
          </ul>
        </div>
        <div className={styles.History}>
          <strong>
            <div className="pc-layer">스탁키퍼의 발자취</div>
            <div className="m-layer">스탁키퍼가 <br />써내려가는 역사</div>
          </strong>

          <HistoryWrap>
            <History2022 />
            <History2021 />
            <History2020 />
          </HistoryWrap>
        </div>
      </StoryContainer>
      {/* <Footer themeType="dark" /> */}
    </>
  );
};

const StoryContainer = styled.div`
  flex-direction: column;
  text-align: center;
  margin-top: -68px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 0;
    padding-top: 0!important;
  }
`;

const HistoryWrap = styled(Container)`
  display: flex;
  gap: 120px;
  flex-direction: column;
  text-align: left;
  @media ${themeDefault.breakpoints.sm} {
    gap: 72px;
  }
`

export default Story;
