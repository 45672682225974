import { themeDefault } from '../../themes/default';
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import useDeviceDetect from 'src/common/util/useDeviceDetect';

type CustomSlideType = {
  children?: any;
  width?: number;
  height?: number;
  controlBtn?: boolean | false;
  position?: string;
  customSlideIndex?: (value: number) => void;
}

const CustomSlide = ({ children, controlBtn, width = 600, position = 'left', customSlideIndex = (value: number) => 0 }: CustomSlideType) => {
  const { isMobile } = useDeviceDetect();
  const [windowWidth, setWindowWinth] = useState(0); // window.innerWidth
  const [left, setLeft] = useState(0); // left 위치 값
  const [imgIndex, setImgIndex] = useState(0); // img index
  const [clckState, setClckState] = useState(false); // 클릭 상태값
  const [mobilechk, setMobilechk] = useState(false);

  useEffect(() => {
    setWindowWinth(window.innerWidth);
    window.addEventListener('resize', function (event) {
      setWindowWinth(window.innerWidth);
    }, true)
  }, [window.innerWidth])

  useEffect(() => {
    // console.log('ismobile', isMobile);
    setMobilechk(isMobile);
  })

  useEffect(() => {
    if(clckState === true ) {
      setTimeout(() => {
        setClckState(false);
      }, 600);
    }
  }, [clckState])

  const ClickPrevArrow = (value: number) => {
    if (left > 0) {
      setLeft(left - width);
      setImgIndex(imgIndex - 1)
      customSlideIndex(imgIndex - 1);
      setClckState(true);
    }
  }
  
  const ClickNextArrow = (value: number | undefined) => {
    if (left < (children.length - 1) * width) {
      setLeft(left + width);
      setImgIndex(imgIndex + 1)
      customSlideIndex(imgIndex + 1);
      setClckState(true);
      // console.log('인덱스 : ',imgIndex);
    }
  }
  return (
    <CustomSlideStyle className='custom-slide' width={600} height={450} windowWidth={windowWidth} left={left} imgIndex={imgIndex} clckState={clckState} position={position} gap={15} isMobile={mobilechk}>
      <div className="viewbox">
        {
          (children && controlBtn && children.length > 1) &&
          <>
            {
              left > 0 &&
              <PrevArrow onClick={() => ClickPrevArrow(width)} />
            }
            {
              left < (children.length - 1) * width && 
              <NextArrow onClick={() => ClickNextArrow(width)} />

            }
          </>
        }
      </div>
      <div className="track" >
        {children}
      </div>
    </CustomSlideStyle>
  )
}

const CustomSlideStyle = styled.div<{ width?: number, height?: number, windowWidth?: number, left?: number, imgIndex?: number, clckState?: boolean, position?: string, gap?: number, isMobile?: boolean }>`
  display: flex;
  position: relative;
  max-width: 100%;
  .viewbox {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: ${(props) => props.width + 'px' || "100%"};
    height: ${(props) => props.height + 'px' || "100%"};
    @media ${themeDefault.breakpoints.sm} {
      width: 100%;
      height: 100%;
    }
  }
  .track {
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    gap: ${(props) => props.gap + 'px' || "15px"};
    > div {
      position: relative;
      flex: 0 0 auto;
      max-width: ${(props) => props.width + 'px' || "100%"};
      height: ${(props) => props.height + 'px' || "100%"};
      width: 100%;
      border: 1px solid #ddd;
      @media ${themeDefault.breakpoints.sm} {
        width: 100%;
        height: auto;
        // transform: none;
      }
      ${props => (props.position === 'center' && props.isMobile === false)
        ?
        `
        transform: translateX( calc( ((${props.windowWidth + 'px'} / 2) - ${props.left + 'px'} - ${props.width + 'px'} / 2) - (${props.imgIndex} * 15px) ));
        `
        :
        `
        transform: translateX( calc( ((${props.windowWidth + 'px'} / 2) - ${props.left + 'px'} - ${props.width + 'px'} / 2) - (${props.imgIndex} * 15px) ));
        `
      }
      ${props => (props.position === 'center' && props.isMobile === true) // 모바일
        ?
        `
        transform: translateX( calc( (((${props.windowWidth + 'px'} - 40px) / 2) - ((${props.windowWidth} - 40) * ${props.imgIndex} * 1px) - 50%) - ${props.imgIndex + 'px'} * 15 ));
        `
        :
        `
        transform: translateX( calc( ((${props.windowWidth + 'px'} / 2) - ${props.left + 'px'} - ${props.width + 'px'} / 2) - (${props.imgIndex} * 15px) ));
        `
      }
      ${props => props.clckState === true
        ?
        `
        transition: all 0.3s;
        `
        :
        `transition: none;
        `
      }
      opacity: 0.5;
      &.is-view {
        opacity: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`

export default CustomSlide