import axios, {AxiosResponse} from "axios";
import {administrativeDivisionResponse, pageRequest, raiserDetailResponse, raiserListResponse} from "../types";
import {useQuery, UseQueryResult} from "react-query";

type ListReturnType = AxiosResponse<raiserListResponse>;

type DetailReturnType = AxiosResponse<raiserDetailResponse>;

type locationReturnType = AxiosResponse<administrativeDivisionResponse>;

const apiUrl = "https://prod.stockeeper.in/bancow/api/v1/raiser/";

const raiserListApi = (prop: pageRequest) => axios.get(`${apiUrl}list`, {params: prop});

export const useRaiserList = (prop: pageRequest): UseQueryResult<ListReturnType, Error> => {
    return useQuery(['raiserList'], () => raiserListApi(prop));
}

const raiserDetailApi = (raiserId: string) => axios.get(`${apiUrl}${raiserId}`);

export const useRaiserDetail = (raiserId: string): UseQueryResult<DetailReturnType, Error> => {
    return useQuery(['raiserDetail'], () => raiserDetailApi(raiserId));
}

const locationApi = () => axios.get(`${apiUrl}administrative-division`)

export const useAdministrativeDivision = (): UseQueryResult<locationReturnType, Error> => {
    return useQuery(['administrative-division'], () => locationApi(), { retry: 5 });
}
