import axios, {AxiosResponse} from "axios";
import {buyerResponse} from "../types";
import {useQuery, UseQueryResult} from "react-query";

type ReturnType = AxiosResponse<buyerResponse>;

const apiUrl = process.env.REACT_APP_BASE_API;

const buyerApi = () => axios.get(`${apiUrl}buyer/public/list`);

const useBuyer = (): UseQueryResult<ReturnType, Error> => {
    return useQuery(['buyer'], () => buyerApi());
}

export default useBuyer;