import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { MainPageTitle } from '../../../../src/common/typography';
import styled from 'styled-components';
import { Container } from '../../../../src/common/layouts';
import { themeDefault } from "../../../../src/themes/default";
import { useEventDetail } from "../../../hooks/useEvent";
import './nowbancowDetail.scss';
import CustomSlide from '../../../components/Slide/CustomSlide';
import classNames from 'classnames';
import RichTextContent from "../../../components/common/RichTextContent";
import Loading from 'src/components/Loading/Loading';

const NowBancowDetail = () => {
  const param = window.location.pathname.split("detail/")[1];
  const eventId = parseInt(param);
  const { isLoading, isError, data } = useEventDetail(eventId || 1);
  const [sendIndex, setSendIndex] = useState(0);

  const [text, setText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    let textvalue = data?.data?.data?.content;
    if (typeof (textvalue) !== 'undefined') {
      setText(textvalue);
    } else {
      setText('')
    }
    if (isError) {
      alert("해당 컨텐츠를 찾을 수 없습니다.");
    }

    if ((!isLoading && !isError)) {
      if (data?.data?.status === "OK" && (data?.data?.data == null || data?.data?.data == undefined)) {
        alert("해당 컨텐츠가 존재하지 않거나 삭제 되었습니다.");
        window.location.href = "/nowbancow";
      }
    }
  }, [data])

  const customSlideIndex = (value: number) => {
    setSendIndex(value);
  }

  return (
    <>
      <NowBancowDetailPage className={'now-bancow-detail-page'}>
        {
          isLoading || isError ? (
            <h2>
              <Loading/>
            </h2>
          ) : data?.data == null ? (
              <h2>
                해당 이벤트가 종료되었습니다.
              </h2>
          ) : (
            <>
              <div className="key_visual">
                <CustomSlide width={600} height={450} controlBtn={true} customSlideIndex={customSlideIndex} position="center">
                  {
                    data?.data?.data?.imageUrlList.map((item, index) => {
                      return (
                        <React.Fragment key={index.toString()}>
                          <div className={classNames('', { 'is-view': index === sendIndex })}>
                            {
                              data?.data?.data?.eventType === 'NOTICE' ?
                                <img src={'https://d1c8bveijgbex6.cloudfront.net/event/%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD+%EC%83%81%EC%84%B8%ED%8E%98%EC%9D%B4%EC%A7%80.png'} alt="" />
                                :
                                <img src={data?.data?.data?.imageUrlList[index]} alt="" />
                            }
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </CustomSlide>
              </div>
              <TextWrap>
                <MainPageTitle> {data?.data?.data?.title}</MainPageTitle>
                <div className="date">
                  {data?.data?.data?.createDate.split('T')[0].replace(/-/g, '.')}
                </div>
                {/*<div className="content ck-content">*/}
                {/*  {data?.data?.data?.content}*/}
                {/*  <div dangerouslySetInnerHTML={{ __html: text }}></div>*/}
                {/*</div>*/}
                <RichTextContent className="content" content={text}/>
                <div className='btn-wrap'>
                  <div className="btn" onClick={() => window.location.href='https://www.bankcow.co.kr/nowbancow'}>
                    목록으로
                  </div>
                </div>
              </TextWrap>
            </>
          )
        }
      </NowBancowDetailPage>
    </>
  )
}

const TextWrap = styled(Container)`

`

const NowBancowDetailPage = styled.div`
  padding-bottom: 159px;
  //text-align: center;
  @media ${themeDefault.breakpoints.sm} {
    padding: 0 20px 48px 20px;
  }
  ${MainPageTitle} {
    margin-top: 80px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 50px;
      font-size: 32px;
    }
  }
  .date {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
    color: #999;
    font-weight: 500;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 14px;
    }
  }
  .content {
    margin-top: 40px;
    font-weight: 500;
    font-size: 20px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 24px;
    }
  }
  .btn-wrap {
    margin-top: 296px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 170px;
    }
  }
  .btn {
    font-size: 16px;
    min-height: 43px;
    padding: 0 46px;
  }
`

export default NowBancowDetail
