import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './howto.module.scss';
import axios from "axios";
import { faqProp } from "../../types";
import Util from "../../common/util/Util";
import AppLoading from "../common/AppLoading";
import RichTextContent from "../common/RichTextContent";
import useDeviceDetect from "../../common/util/useDeviceDetect";

type Data = {
  faqId: any;
  onBackClick?: VoidFunction;
}

const HowToUseDetail = ({ faqId, onBackClick }: Data) => {
  const { isMobile } = useDeviceDetect();
  const param = window.location.pathname.split("/");
  const [faqDetail, setFaqDetail] = useState<faqProp>({
    "id": 0,
    "title": "",
    "message": "",
    "createDate": "",
    "updateDate": "",
    "username": "",
    "category": "",
  });
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const fetchFaqDetail = (faqDetailId: number) => {
    axios.get(`${process.env.REACT_APP_BASE_API}faq/public/${faqDetailId}`)
      .then(result => {
        setFaqDetail(result?.data?.data);
        setIsFetching(false);
        setContent(Util.replaceEmbedContents(result?.data?.data.message));
      })
      .catch(e => alert("잠시 후에 다시 시도해주세요. 이용에 불편을 드려 죄송합니다."));
  }
  const [content, setContent] = useState('');

  useEffect(() => {
    // if (data?.data?.data.message) {
      // Util.processEmbedContent(data?.data?.data.message).then(textProcessed => {
      //   setContent(textProcessed);
      // });
    //   setContent(Util.replaceEmbedContents(data?.data?.data.message));
    // }
    if (isMobile)
      window.scrollTo(0, 0);
    fetchFaqDetail(param[param.length - 1] === 'notice' ? faqId : parseInt(param[param.length - 1]));
  }, [isFetching]); //data]);

  const navigate = useNavigate();
  return (
    <>
      {
        isFetching ? <AppLoading /> :
            (
                <div className={classNames(styles.list_detail)}>
                    <div className={styles.heading}>
                      {
                        isMobile ? (
                            <div className={styles.back}
                                 onClick={() => navigate(-1)}>
                              &lt; 뒤로가기
                            </div>
                        ) : (
                            <div className={styles.back}
                                 onClick={onBackClick}>
                              &lt; 뒤로가기
                            </div>
                        )
                      }
                    <div className={classNames(styles.date, 'pc-layer')}>{faqDetail?.createDate}</div>
                </div>
                <div className={styles.content}>
                  <div className={styles.content__question}>{faqDetail?.title}</div>
                  <div className={classNames(styles.date, 'm-layer')}>{faqDetail?.createDate}</div>
                  <div className={styles.content__content} dangerouslySetInnerHTML={{ __html: content }}>
                  </div>
                </div>
              </div>
            )
      }
    </>
  )
}

export default HowToUseDetail