import React from 'react'
import styled from 'styled-components'
import { themeDefault } from '../../themes/default'

const SubSlogan = () => {
  return (
    <SubSloganStyle>
      <p>자산의 관점을 넘은 새로운 것들이 자산이 되는 시대 <br />뱅카우와 함께하는 한우 펀딩이 <br />당신의 자산에 새로움을 불어넣습니다</p>
    </SubSloganStyle>
  )
}

const SubSloganStyle = styled.div`
  display: flex;
  padding: 140px 0;
  background:  #F7F7F7;
  font-size: 32px;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  line-height: 38.41px;
  @media ${themeDefault.breakpoints.sm} {
    padding: 122px 0 100px 0;
    font-size: 16px;
    line-height: 24px;
  }
`

export default SubSlogan