import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoX } from 'react-icons/go';
import styled from 'styled-components';
import { themeDefault } from '../../themes/default';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu: React.FC<ModalProps> = ({ isOpen, setIsOpen }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);
  return (
    <MenuModal className={`${isOpen && 'active'}`}>
      <Menu>
        <CloseBox onClick={() => setIsOpen(false)} />
        <Link to="/">
          <Item onClick={() => setIsOpen(false)}>메인</Item>
        </Link>
        <a href={'https://funding.bankcow.co.kr'}>
          <Item onClick={() => setIsOpen(false)}>한우투자</Item>
        </a>
        <Link to="/story">
          <Item onClick={() => setIsOpen(false)}>회사소개</Item>
        </Link>
        <Link to="/notice">
          <Item onClick={() => setIsOpen(false)}>고객센터</Item>
        </Link>
        <Link to="/nowbancow">
          <Item onClick={() => setIsOpen(false)}>공지사항</Item>
        </Link>
        <Link to="/inquiry">
          <Item onClick={() => setIsOpen(false)}>입점/제휴문의</Item>
        </Link>
      </Menu>
    </MenuModal>
  );
};

export const MenuModal = styled.div`
	display: none;
	@media ${themeDefault.breakpoints.sm} {
		display: block;
		position: fixed;
		overflow: hidden;
		height: 0px;
		&.active {
			transition: ease all 0.5s;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 100;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.94);
		}
	}
`;

const Menu = styled.div`
	position: relative;
	padding: 80px;
`;

const Item = styled.h3`
	font-size: 23px;
	color: #fff;
	margin-top: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const CloseBox = styled(GoX)`
	position: absolute;
	font-size: 26px;
	color: #fff;
	right: 80px;
	top: 10%;
`;

export default MobileMenu;
