import axios, {AxiosResponse} from "axios";
import {faqCategoryResponse, faqDetailResponse, faqRequestType, faqResponse} from "../types";
import {useQuery, UseQueryResult} from "react-query";

type ListReturnType = AxiosResponse<faqResponse>;

type DetailReturnType = AxiosResponse<faqDetailResponse>;

type FaqCategoryReturnType = AxiosResponse<faqCategoryResponse>;

const apiUrl = process.env.REACT_APP_BASE_API + 'faq/';

const faqListApi = (prop: faqRequestType) => axios.post(`${apiUrl}public/list`, prop);

export const useFaqList = (prop: faqRequestType): UseQueryResult<ListReturnType, Error> => {
    return useQuery(['faqList'], () => faqListApi(prop), { enabled: true, cacheTime: 5000 });
}

const faqDetailApi = (faqId: number) => axios.get(`${apiUrl}public/${faqId}`);

export const useFaqDetail = (faqId: number): UseQueryResult<DetailReturnType, Error> => {
    return useQuery(['faqDetail'], () => faqDetailApi(faqId), { enabled: true, cacheTime: 5000 });
}

const faqCategoryApi = () => axios.get(`${apiUrl}category/list`);

export const useFaqCategory = (): UseQueryResult<FaqCategoryReturnType, Error> => {
    return useQuery(['faqCategory'], () => faqCategoryApi());
}
