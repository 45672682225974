import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import {faqCategoryProp} from "../../types";

const qnaData = {
  title: '카테고리 선택',
  Data: ['뱅카우 사용 시 불편사항', '뱅카우 개선사항 건의', '기타'],
  type: ['BUG', 'PARTNER', 'ETC', 'INVESTMENT',]
}

const faqData = {
  title: '전체',
  Data: ['예치금', '계정', '펀딩', '소비자 보호', '용어', '입점'],
}

const inquiryData = [
  {
    title: '전체',
    Data: ['계정', '예치금', '펀딩', '소비자 보호', '용어', '입점']
  }
]

type dataType = {
  dataName?: string;
  dropdownvalue?: (value: string) => void;
  filterData?: number;
  faqCategoryList?: Array<faqCategoryProp>;
}

const DropDown = ({ dataName = 'qna', dropdownvalue = (value: any) => '', filterData, faqCategoryList }: dataType) => {
  // const qnaData = ['뱅카우 사용 시 불편사항', '뱅카우 개선사항 건의', '기타']
  const [selectText, setSelectText] = useState('');
  const [clickList, setClickList] = useState(false);
  const [view, setView] = useState(false);

  useEffect(() => {
    if (dataName === 'qna') {
      setSelectText(qnaData.title)
    }
    if (dataName === 'inquiry') {
      setSelectText(inquiryData[0].title)
    }
    if (dataName === 'faq') {
      setSelectText(faqData.title)
    }
  }, [dataName])

  const ListClick = (item: string, key: any) => {
    setSelectText(item);
    setClickList(true);
    setView(false);
    if (dataName === 'faq')
      dropdownvalue(key);
    else
      dropdownvalue(qnaData.type[key]);
  }

  const OpenClick = () => {
    setView(!view);
  }

  return (
    <>
      <div className={classNames('DropDown-area')}>
        <strong onClick={OpenClick} className={classNames("", { 'is-selected': clickList })}>
          { selectText }
        </strong>
        {
          view &&
          <ul>
            {
              dataName === 'qna' && qnaData.Data.map((item, key) => {
                return (
                  <li
                    key={key}
                    className={classNames('', { 'is-select': selectText === item })}
                    // className={classNames('')}
                    onClick={() => ListClick(item, key)}
                  >
                    {item}
                  </li>
                )
              })
            }
            {
              (dataName === 'faq' && faqCategoryList) && faqCategoryList.map((item, key) => {
                  return (
                      <li
                          key={key}
                          className={classNames('', { 'is-select': selectText === item.category })}
                          // className={classNames('')}
                          onClick={() => ListClick(item.category, item.id)}
                      >
                        {item.category}
                      </li>
                  )
                })
              //   faqData.Data.map((item, key) => {
              //   return (
              //     <li
              //       key={key}
              //       className={classNames('', { 'is-select': selectText === item })}
              //       // className={classNames('')}
              //       onClick={() => ListClick(item, key)}
              //     >
              //       {item}
              //     </li>
              //   )
              // })
            }

          </ul>
        }
      </div>
    </>
  )
}

export default DropDown;