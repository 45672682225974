import React from 'react'
import styled from 'styled-components';

type FakerChartType = {
  widthValue?: number;
  heightValue?: number;
  backgroundValue?: string;
  text?: string;
}

const FakerChart = ({ widthValue, heightValue, backgroundValue, text }: FakerChartType) => {
  return (
    <FakerChartStyle>
      <div style={{ width: widthValue, height: heightValue, background: backgroundValue}}></div>
      <span>{text}</span>
    </FakerChartStyle>
  )
}

const FakerChartStyle = styled.div`
  div {
    border-radius: 10px 10px 0px 0px;
  }
  span {
    display: block;
    margin-top: 9px;
    color: #999;
    text-align: center;
  }
`

export default FakerChart