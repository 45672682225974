import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './assets/styles/global.scss';
import './assets/styles/form.scss';
import './assets/styles/ckeditor-styles.scss';
import { CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from "react-query";
// const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; // 발급받은 추적ID를 환경 변수로 불러온다.
// console.log('TRACKING_ID', TRACKING_ID);
// ReactGA.initialize(TRACKING_ID);
ReactGA.initialize('UA-222889015-1');

const queryClient = new QueryClient();

ReactDOM.render(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>,
    document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
