import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { useTerms } from 'src/hooks/useTerms';
import Loading from 'src/components/Loading/Loading';
import './terms.scss';

type Params = {
  termsCode: string;
}

const Terms = () => {
  const { termsCode } = useParams<Params>();
  const { isFetched, isLoading, data } = useTerms(termsCode || '');

  return (
    <div className='terms-page'>
      {
        !isFetched || isLoading ? <><Loading /></>
          : (
            <>
              <div className="title">
                {
                  termsCode === 'C024001' && <span>뱅카우 서비스 이용약관</span>
                }
                {
                  termsCode === 'C024002' && <span>개인정보 처리방침</span>
                }
                {
                  termsCode === 'C024004' && <span>구매회원 이용약관</span>
                }
                {
                  termsCode === 'C024003' && <span>마케팅 활용정보 제공</span>
                }
              </div>
              <div className="date">{data?.data?.response?.create_dt} 업데이트</div>
              <div className='content'>{data?.data?.response?.content}</div>
            </>
          )
      }
    </div>
  )
}

export default Terms