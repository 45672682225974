import styled from 'styled-components';
import { themeDefault } from '../../themes/default';
import bankcowTypoLogo from '../../components/home/img/bankcow_typo_logo.png'
type LogoType = {
  color?: string;
}

const BancowTypoLogo = ({ color = '#004BFA' }: LogoType) => {
  return (
      <LogoBox>
         <img src={bankcowTypoLogo} alt={'bankcow typo logo'} />
          {/*<svg*/}
          {/*  preserveAspectRatio="xMidYMid meet"*/}
          {/*  data-bbox="0 0 1145.3 210.44"*/}
          {/*  viewBox="0 0 1145.3 210.44"*/}
          {/*  xmlns="http://www.w3.org/2000/svg"*/}
          {/*  data-type="color"*/}
          {/*  role="presentation"*/}
          {/*  aria-hidden="true"*/}
          {/*  aria-labelledby="svgcid--mqiv7ihszraa"*/}
          {/*>*/}
          {/*  <title id="svgcid--mqiv7ihszraa"></title>*/}
          {/*  <g>*/}
          {/*    <path d="M766.87 75.79c-30.64 0-55.57 24.93-55.57 55.57s24.93 55.57 55.57 55.57 55.57-24.93 55.57-55.57-24.93-55.57-55.57-55.57m0 134.65c-43.6 0-79.07-35.47-79.07-79.07 0-43.6 35.47-79.07 79.07-79.07 43.6 0 79.07 35.47 79.07 79.07 0 43.59-35.47 79.07-79.07 79.07" fill={color} ></path>*/}
          {/*    <path d="M245.39 184.71c-29.42 0-53.35-23.93-53.35-53.35s23.93-53.35 53.35-53.35 53.35 23.93 53.35 53.35-23.93 53.35-53.35 53.35m96.42-6.09l-23.39-23.39a76.639 76.639 0 0 0 3.81-23.87c0-42.38-34.47-76.85-76.85-76.85-42.38 0-76.85 34.47-76.85 76.85 0 42.38 34.47 76.85 76.85 76.85 25.24 0 47.68-12.24 61.7-31.08l18.11 18.11c2.3 2.3 5.3 3.44 8.31 3.44 3.01 0 6.02-1.15 8.31-3.44 4.59-4.59 4.59-12.03 0-16.62" fill={color}></path>*/}
          {/*    <path d="M76.85 184.71c-29.42 0-53.35-23.93-53.35-53.35s23.93-53.35 53.35-53.35 53.35 23.93 53.35 53.35-23.93 53.35-53.35 53.35m0-130.2c-20.71 0-39.52 8.25-53.35 21.61V11.75C23.5 5.26 18.24 0 11.75 0S0 5.26 0 11.75v121.09c0 .17.04.33.05.5 1.05 41.46 35.08 74.88 76.8 74.88 42.38 0 76.85-34.47 76.85-76.85 0-42.38-34.47-76.86-76.85-76.86" fill={color}></path>*/}
          {/*    <path d="M677.8 208.21h-65.1c-42.38 0-76.85-34.48-76.85-76.85 0-42.38 34.48-76.85 76.85-76.85h33.13c6.49 0 11.75 5.26 11.75 11.75s-5.26 11.75-11.75 11.75H612.7c-29.42 0-53.35 23.93-53.35 53.35 0 29.41 23.93 53.35 53.35 53.35h65.1c6.49 0 11.75 5.26 11.75 11.75s-5.26 11.75-11.75 11.75" fill={color}></path>*/}
          {/*    <path d="M505.52 208.21c-6.49 0-11.75-5.26-11.75-11.75v-65.1c0-29.42-23.93-53.35-53.35-53.35-29.41 0-53.35 23.93-53.35 53.35v65.1c0 6.49-5.26 11.75-11.75 11.75s-11.75-5.26-11.75-11.75v-65.1c0-42.38 34.47-76.85 76.85-76.85s76.85 34.47 76.85 76.85v65.1c0 6.49-5.26 11.75-11.75 11.75" fill={color}></path>*/}
          {/*    <path d="M1145.3 64.04c0-6.49-5.26-11.75-11.75-11.75s-11.75 5.26-11.75 11.75v67.32c0 29.42-23.93 53.35-53.35 53.35-29.41 0-53.34-23.92-53.35-53.33V64.04c0-6.49-5.26-11.75-11.75-11.75s-11.75 5.26-11.75 11.75v67.34c-.01 29.41-23.94 53.33-53.35 53.33-29.42 0-53.35-23.93-53.35-53.35V64.04c0-6.49-5.26-11.75-11.75-11.75s-11.75 5.26-11.75 11.75v67.32c0 42.38 34.47 76.85 76.85 76.85 27.41 0 51.49-14.43 65.1-36.08 13.61 21.65 37.69 36.08 65.1 36.08 42.38 0 76.85-34.47 76.85-76.85V64.04z" fill={color}></path>*/}
          {/*  </g>*/}
          {/*</svg>*/}
      </LogoBox>
  );
};

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
	height: 27px;
	// svg {
	// 	fill: ${themeDefault.alt.text1};
	// }
	// @media ${themeDefault.breakpoints.md} {
	// 	svg {
	// 		fill: #fff;
	// 	}
	// }
`;

export default BancowTypoLogo;
