import React from 'react'
import styled from 'styled-components'
import { Container, HomeTag, Radius30WhiteBox, Radius30WhiteBox2, Radius30WhiteBox3 } from '../../../common/layouts'
import { MainPageTitle } from '../../../common/typography';
import useDeviceDetect from '../../../common/util/useDeviceDetect';
import FakerChart from '../../chart/FakerChart';
import styles from '../homepage.module.scss';
import useDeviceDetecteDetect from '../../../common/util/useDeviceDetect';
import { themeDefault } from '../../../themes/default';
import './mobileCard.scss';
import Slider from 'react-slick';
import classNames from 'classnames';

const GrowthPotential = () => {
  const { isMobile } = useDeviceDetecteDetect();

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // centerMode: true,
    // centerPadding: 30
  };

  return (
    <GrowthPotentialStyle>
      <AreaWrap>
        <div className='title-wrap'>
          <HomeTag>
            성장성
          </HomeTag>
          <MainPageTitle>소는 <br />소비를 바탕으로 <br />성장해요
          {
            isMobile ? <span>2015년 대비</span> : <span>(2015년 대비)</span>
          }
          </MainPageTitle>
          <p>나라별 GDP 성장률</p>
        </div>
        {
          isMobile ?
            // <Slider {...settings} className="card-slide one">
            //   <Card className='cardwrap-card'>
            //     <span>한국</span>
            //     <Price>+31.6%</Price>
            //   </Card>
            //   <Card className='cardwrap-card'>
            //     <span>일본</span>
            //     <Price>+7.7%</Price>
            //   </Card>
            //   <Card className='cardwrap-card'>
            //     <span>중국</span>
            //     <Price>+36.4%</Price>
            //   </Card>
            //   <Card className='cardwrap-card'>
            //     <span>미국</span>
            //     <Price>+26.3%</Price>
            //   </Card>
            // </Slider>
            <CardWrap className="css-slider">
              <div className="css-slide-track">
                <Card className='cardwrap-card css-slide'>
                  <span>한국</span>
                  <Price>+31.6%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>일본</span>
                  <Price>+7.7%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>중국</span>
                  <Price>+36.4%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>미국</span>
                  <Price>+26.3%</Price>
                </Card>

                <Card className='cardwrap-card css-slide'>
                  <span>한국</span>
                  <Price>+31.6%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>일본</span>
                  <Price>+7.7%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>중국</span>
                  <Price>+36.4%</Price>
                </Card>
                <Card className='cardwrap-card css-slide'>
                  <span>미국</span>
                  <Price>+26.3%</Price>
                </Card>
              </div>
            </CardWrap>
            :
            <CardWrap>
              <Card className='cardwrap-card'>
                <span>한국</span>
                <Price>+31.6%</Price>
              </Card>
              <Card className='cardwrap-card'>
                <span>일본</span>
                <Price>+7.7%</Price>
              </Card>
              <Card className='cardwrap-card'>
                <span>중국</span>
                <Price>+36.4%</Price>
              </Card>
              <Card className='cardwrap-card'>
                <span>미국</span>
                <Price>+26.3%</Price>
              </Card>
            </CardWrap>
        }
      </AreaWrap>
      <GraphWrap>
        {
          isMobile &&
          <>
            <div className={classNames(`${'nation-wrap mobile'} ${styles.nation_wrap}`, 'css-slider')}>
              <div className="css-slide-track">
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>한국</span>
                  <Price>+19.0%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>일본</span>
                  <Price>+21.7%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>중국</span>
                  <Price>+24.5%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>미국</span>
                  <Price>+6.0%</Price>
                </Card2>

                <Card2 className='graphwrap-card nation css-slide'>
                  <span>한국</span>
                  <Price>+19.0%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>일본</span>
                  <Price>+21.7%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>중국</span>
                  <Price>+24.5%</Price>
                </Card2>
                <Card2 className='graphwrap-card nation css-slide'>
                  <span>미국</span>
                  <Price>+6.0%</Price>
                </Card2>
              </div>
            </div>
          </>
        }
        {/* <div className={`${'nation-wrap mobile'} ${styles.nation_wrap}`}>
          <Slider {...settings} className="card-slide">

          </Slider>
        </div> */}

        <Card2 className='graphwrap-card sobi'>
          <div className="subtitle one">주요국 소고기 소비 평균 증가율 <span></span></div>
          <Price>+17.8%</Price>
          <div className="FakerChart-wrap one">
            <FakerChart widthValue={60} heightValue={144} backgroundValue="#8EB0FF" text="2015" />
            <FakerChart widthValue={60} heightValue={173} backgroundValue="#8EB0FF" text="2021" />
          </div>
        </Card2>
        <div className="wrap">
          <Card3 className='graphwrap-card growth'>
            <div className="subtitle two">OECD 국가 GDP 성장 <span></span></div>
            <Price>+25.8%</Price>
            <div className="FakerChart-wrap">
              <FakerChart widthValue={60} heightValue={98} backgroundValue="#8EB0FF" text="2017" />
              {
                !isMobile &&
                <>
                  <FakerChart widthValue={60} heightValue={105} backgroundValue="#8EB0FF" text="2018" />
                  <FakerChart widthValue={60} heightValue={112} backgroundValue="#8EB0FF" text="2019" />
                  <FakerChart widthValue={60} heightValue={110} backgroundValue="#8EB0FF" text="2020" />
                </>
              }
              <FakerChart widthValue={60} heightValue={125} backgroundValue="#8EB0FF" text="2021" />
            </div>
          </Card3>
          <div className={`${'nation-wrap'} ${styles.mo_none}`}>
            <Card2 className='graphwrap-card nation'>
              <span>한국</span>
              <Price>+19.0%</Price>
            </Card2>
            <Card2 className='graphwrap-card nation'>
              <span>일본</span>
              <Price>+21.7%</Price>
            </Card2>
            <Card2 className='graphwrap-card nation'>
              <span>중국</span>
              <Price>+24.5%</Price>
            </Card2>
            <Card2 className='graphwrap-card nation'>
              <span>미국</span>
              <Price>+6.0%</Price>
            </Card2>
          </div>
        </div>
      </GraphWrap>
      <p className="tail-ment">나라별 소고기 소비량 증가율</p>
    </GrowthPotentialStyle>
  )
}

const GrowthPotentialStyle = styled(Container)`
  margin-top: 302px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 120px;
  }
  ${MainPageTitle} {
    span {
      display: block;
      color: #999;
      font-size: 20px;
      font-weight: 400;
      @media ${themeDefault.breakpoints.sm} {  
        font-size: 12px;
      }  
    }
  }
  .tail-ment {
    margin-top: 20px;
    color: #999;
    text-align: right;
    @media ${themeDefault.breakpoints.sm} {  
      margin-top: 10px;
      margin-right: 20px;
    }
  }
  .slick-list {
    margin: 0 -15px;
  }
  .slick-slide {
    width: 150px!important;
    // margin: 0 10px;
  }
  .card-slide {
    @media ${themeDefault.breakpoints.sm} {  
      overflow: hidden;
      .slick-list {
        padding-bottom: 20px;
      }
      &.one {
        margin-top: 10px;
        .slick-list {
          padding-top: 10px;
          padding-bottom: 30px;
        }
      }
    }
  }
`
const SubTitle = styled.div``

const AreaWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  @media ${themeDefault.breakpoints.sm} {
    flex-direction: column;
    align-items: initial;
    overflow:
    .cardwrap {
      max-width: 100%;
      overflow: auto;
    }
  }
  ${MainPageTitle} {
    line-height: 54px;
    margin-top: 20px;
    @media ${themeDefault.breakpoints.sm} {
      line-height: 36px;
      font-size: 28px;
    }
    + p {
      @media ${themeDefault.breakpoints.sm} {
       margin-top: 10px;
       font-size: 12px;
      } 
    }
  }
  .title-wrap {
    @media ${themeDefault.breakpoints.sm} {
      padding: 0 20px;
    }
    p {
      position: absolute;
      right: 0;
      top: 37px;
      color: #999;
      @media ${themeDefault.breakpoints.sm} {
        position: static;
      }
    }
  }
`

const Price = styled.div`
  color: #333;
  font-size: 32px;
  font-weight: 700;
`

const CardWrap = styled.div`
  display: flex;
  gap: 20px;
  transform: translateY(-36px);
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 2px;
    padding-bottom: 30px;
    transform: translateY(14px);
  }
`

const Tag = styled.div`
`
const Card = styled(Radius30WhiteBox)`
  &.cardwrap-card {
    display: flex;
    width: 150px;
    height: 150px;
    flex-direction: column;
    text-align: center;
    padding: 12px;
    @media ${themeDefault.breakpoints.sm} {
      box-shadow: 2px 8px 8px 1px #E8EFFF;
      flex: 0 0 auto;
    }
    span {
      color: #999;
      font-weight: 700;
    }
    ${Price} {
      display: block;
      margin-top: 36px;
    }
  }
`

const Card2 = styled(Radius30WhiteBox2)`
  &.sobi {
    padding: 20px;
    ${Price} {
      margin-top: 51px;
      font-size:36px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 38px;
        font-size: 28px;
      }
    }
  }
  &.sobi {
    width: 300px;
    height: 400px;
    @media ${themeDefault.breakpoints.sm} {
      width: calc(100% - 40px);
      margin-top: 25px;
      margin-left: 20px;
    }
  }
  &.nation {
    width: 150px;
    height: 200px;
    text-align: center;
    @media ${themeDefault.breakpoints.sm} {
      height: 150px;
      flex: 0 0 auto;
    }
    span {
      color: #999;
      font-weight: 700;
    }
    strong {
      display: block;
      margin-top: 36px;
      color: #333;
      font-weight: 700;
      font-size: 32px;
    }
  }
  &.sobi, &.nation {
    background: #E8EFFF;
  }
`

const Card3 = styled(Radius30WhiteBox3)`
  ${Price} {
    margin-top: 21px;
    font-size: 36px;
  }
  &.growth {
    margin-top: -11px;
    width: 660px;
    height: 280px;
    padding: 20px;
    @media ${themeDefault.breakpoints.sm} {
      width: 100%;
      margin-top: 10px;
    }  
  }
`

const GraphWrap = styled.div`
  display:flex;
  align-items: end;
  @media ${themeDefault.breakpoints.sm} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .FakerChart-wrap {
    display: flex;
    margin-top: -6px;
    justify-content: space-around;
    align-items: flex-end;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: -7px;
    }
    &.one {
      margin-top: 39px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 62px;
      }
    }
  }
  ${Card} {
    padding: 20px;
    ${Price} {
      margin-top: 21px;
      font-size: 36px;
    }
  }
  .nation-wrap {
    display: flex;
    gap: 20px;
    margin-top: 32px;
    @media ${themeDefault.breakpoints.sm} {
      padding-bottom: 10px;
    }
    &.mobile {
      display: none;
      @media ${themeDefault.breakpoints.sm} {
        display: block;
        overflow: hidden;
        .slick-list {
          padding: 0 0 15px 0;
        }
      }
    }
    .graphwrap-card {
      padding: 0;
      padding-top: 20px;
      ${Price} {
        margin-top: 53px;
        @media ${themeDefault.breakpoints.sm} {
          margin-top: 33px;
        }
      }
    }
  }
  .wrap {
    margin-left: 60px;
    @media ${themeDefault.breakpoints.sm} {
      margin-left: 20px;
      width: calc(100% - 40px);
    }
  }
  .subtitle {
    font-size: 20px;
    font-weight: 700;
    color: #999;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 16px;
    }
    &.one {
      span {
        display: block;
        margin-top: 9px;
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      @media ${themeDefault.breakpoints.sm} {
        display: block;
        margin-top: 6px;
        font-size: 18px;
      }
    }
  }
`

export default GrowthPotential