import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import styled from 'styled-components';
import Footer from '../../common/global/Footer';
import GNB from '../../common/global/GNB';
import { Container } from '../../common/layouts';
import NextArrow from '../../components/Slide/NextArrow';
import PrevArrow from '../../components/Slide/PrevArrow';
import './farmDetail.scss';
import { useRaiserDetail } from "../../hooks/useRaiser";
import CustomSlide from '../../components/Slide/CustomSlide';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading/Loading';
import classNames from 'classnames';
import { replace } from 'lodash';

const SlideData = [
  {
    imgurl: '',
  },
  {
    imgurl: '',
  },
  {
    imgurl: '',
  },
  {
    imgurl: '',
  },
  {
    imgurl: '',
  },
]

export interface ILength {
  username: number | undefined;
}

const FarmDetail = () => {
  const param = window.location.pathname.split("/");
  const raiserId: string = param[param.length - 1];
  const { isLoading, isError, data } = useRaiserDetail(raiserId);
  const [datalength, setDatalength] = useState<number | undefined>(undefined);
  const [sendIndex, setSendIndex] = useState(0);
  const navigate = useNavigate();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]

    // dots: true,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 4,
    // slidesToScroll: 4,
    // initialSlide: 0,
  };

  useEffect(() => {
    setDatalength(data?.data?.response.fileInfoList.length);
    window.scrollTo(0, 0)
  }, [data]);

  useEffect(() => {
    localStorage.setItem('location', 'true');
  }, [])

  const customSlideIndex = (value: number) => {
    setSendIndex(value);
  }

  return (
    <>

      {
        isError || isLoading ?
          (
            <h2><Loading /></h2>
          ) : (
            <>
              {/* <GNB /> */}
              <FarmDetailPage className='farm-detail-page'>
                <Container>
                  <div className="back"
                    // onClick={() => navigate(-1)}
                    onClick={() => navigate('/', { state: 3000 })}
                  >&lt; 뒤로가기
                  </div>
                </Container>
                {/* 슬라이드 */}
                {
                  datalength !== undefined && datalength < 2 ?
                    <>
                      {
                        data?.data?.response.fileInfoList.map((item, index) => {
                          return (
                            <div className="imgwrap">
                              <img src={`${item.image_file_url}`} alt="농장이미지" />
                            </div>
                          )
                        })
                      }
                    </>
                    :
                    <CustomSlide width={600} height={450} controlBtn={true} customSlideIndex={customSlideIndex} position="center">
                      {
                        data?.data?.response.fileInfoList.map((item, index) => {
                          return (
                            <React.Fragment key={index.toString()}>
                              <div className={classNames('', { 'is-view': index === sendIndex })}>
                                <img src={`${item.image_file_url}`} alt="농장이미지" />
                              </div>
                            </React.Fragment>
                          )
                        })
                      }
                    </CustomSlide>

                  // <Slider {...settings} className="farm-detail-slide">
                  //   {
                  //     data?.data?.response.fileInfoList.map((item, index) => {
                  //       return (
                  //         <div className="imgwrap">
                  //           <img src={`${item.image_file_url}`} alt="농장이미지" />
                  //         </div>
                  //       )
                  //     })
                  //   }
                  // </Slider>
                }
                <Container>
                  <div className="title">{data?.data?.response?.slogan}</div>
                  <div className="detail-header">
                    <ul>
                      <li className='header'>
                        <strong>{data?.data?.response.raiserUserName} 대표님</strong>
                        <strong>{data?.data?.response.raiserName}</strong>
                        {data?.data?.response.farmAddress}
                      </li>
                      <li>
                        <div className="tag">
                          <strong>경력</strong>
                          <span>{data?.data?.response.raiserCareerYear}년</span>
                        </div>
                        <div className="tag">
                          <strong>작년생산등급</strong>
                          <span>{`${data?.data?.response.productionQualityGradeRank}${data?.data?.response.productionAmountGradeRank}`}</span>
                        </div>
                        <div className="tag">
                          <strong>사육두수</strong>
                          <span>500두</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{
                    __html: `${data?.data?.response.story}`
                      .replaceAll('\r\n', '<br/>')
                      .replaceAll('\r', '<br/>')
                      .replaceAll('\n', '<br/>')
                  }}>

                  </div>
                </Container>
              </FarmDetailPage>
            </>
          )
      }
    </>
  )
}

const FarmDetailPage = styled.div`
`

export default FarmDetail