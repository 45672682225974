import React from 'react'
import Counting from './components/Counting'
import GrowthChart from './components/GrowthChart'
import GrowthPotential from './components/GrowthPotential'
import Stability from './components/Stability'

const Discription = () => {
  return (
    <div>
      <Counting />
      <GrowthPotential />
      <Stability />
      <GrowthChart />
    </div>
  )
}

export default Discription