import DetailLink from './components/DetailLink';
import AppLinkBox from '../../common/global/AppLinkBox';
import MainLeftImg from './components/MainLeftImg';
import SloganText from './components/SloganText';
import ReviewsSlider from './components/ReviewsSlider';
import styled from 'styled-components';
import { review } from '../../pages';
import { themeDefault } from '../../themes/default';
import { useEffect, useRef, useState } from 'react';
import './arrow-swing.scss';
import styles from './homepage.module.scss';

const Slogan = () => {
  const mainVideoSource = 'https://d1c8bveijgbex6.cloudfront.net/video/240119+Bankcow+Final+Comp+Full.mp4';
  const [ScrollY, setScrollY] = useState(0);  // 스크롤값을 저장하기 위한 상태
  const [showMainSloganText, setShowMainSloganText] = useState(false);
  const [showSubSloganText, setShowSubSloganText] = useState(false);
  const [showAppLink, setShowAppLink] = useState(false);
  const [muted, setMuted] = useState(true);

  const videoElementRef = useRef<HTMLVideoElement>(null);

  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  }

  useEffect(() => {
    if (ScrollY <= 500) {
      // TODO: 리덕스로 높이값 가져와서 높이 이하에서 스크롤 할경우 한번에 넘어가기 
    }
  }, [ScrollY])

  useEffect(() => {
    if (!videoElementRef.current) {
      return;
    }
    videoElementRef.current.addEventListener('timeupdate', () => {
      let currentTime = videoElementRef.current?.currentTime || 0;
      if (currentTime > 8.5) {
        setShowMainSloganText(true);
      }
      if (currentTime > 8.8) {
        setShowSubSloganText(true);
      }
      if (currentTime > 9) {
        setShowAppLink(true);
      }
    });
  }, [videoElementRef])

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  })

  return (
    <Section>
      <Container>
        <Box>
          <SloganText showMainSloganText={showMainSloganText} showSubSloganText={showSubSloganText}/>
          {showAppLink && <AppLinkBox />}
        </Box>
        <div className="video-wrap">
          <video
            // poster="/static/media/test-bg@2x.87b3354e.jpg"
            autoPlay
            playsInline={true}
            muted={muted}
            loop={false}
            preload="auto"
            controls={false}
            ref={videoElementRef}
          >
            <source src={mainVideoSource} type="video/mp4" />
          </video>
        </div>
        {
            muted 
            ? <div className={`control-icon ${styles.muteicon}`} onClick={() => setMuted(false)}/>
            : <div className={`control-icon ${styles.unmuteicon}`} onClick={() => setMuted(true)}/>
          }
        <div className="arrow-area"></div>
      </Container>
      {/* <ReviewsSlider reviews={reviews} /> */}
    </Section>
  );
};

const Section = styled.div`
  display: flex;
	position: relative;
	transition: ease all 0.5s;
	overflow: hidden;
  max-height: calc(100vh + 0px);
  width: 100%;
  height: 56.24vw;
  @media ${themeDefault.breakpoints.sm} {
    // height: 56.2vw;
    height: calc(var(--vh) * 100);
    max-height: none;
  }
  video {
    position: absolute;
    top: -1000%;
    left: -1000%;
    right: -1000%;
    bottom: -1000%;
    height: 150%;
    margin: auto;
    @media ${themeDefault.breakpoints.sm} {
      max-width: none;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }
  }
  .video-wrap {
    @media ${themeDefault.breakpoints.sm} {
      position: absolute; 
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.4);
      content:'';
      z-index: 1;
    }
  }
  .control-icon {
    position: absolute;
    top: 80px;
    right: 10%;
    cursor: pointer;
    z-index: 100;
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
  }
  .arrow-area {

  }
`;

export const Container = styled.div`
	margin: 0 auto;
	display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
	@media ${themeDefault.breakpoints.sm} {
    align-items: end;
    width: auto;
	}
`;

export const Box = styled.div`
	position: relative;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  width: 100%;
  text-align: left;
  z-index: 2;
  @media ${themeDefault.breakpoints.sm} {
    position: absolute;
    bottom: 40px;
    margin-top: 0;
    width: auto;
  }
`;

export default Slogan;
