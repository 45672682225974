import styled from "styled-components";
import { themeDefault } from "../themes/default";

export const HeadLine2 = styled.h2`
  font-weight: 700;
  font-size: 60px;
  line-height: 1.45;
  color: ${themeDefault.alt.text1};
`;
export const HeadLine3 = styled.h3`
  font-weight: 700;
  font-size: 47px;
  @media ${themeDefault.breakpoints.sm} {
    font-size: 16px;
  }
`;

export const HeadLine4 = styled.h4`
  font-size: 33px;
  color: ${themeDefault.alt.text1};
  @media ${themeDefault.breakpoints.sm} {
    font-size: 22px;
  }
`;

export const HeadLine5 = styled.h5`
  font-size: 23px;
  
  @media ${themeDefault.breakpoints.sm} {
    font-size: 18px;
  }
`;

export const HeadLine6 = styled.h6`
  font-size: 15px;
`;

export const Emphasis = styled.span`
  color: ${(props) => props.theme.colors.point};
`;

export const EmphasisSub = styled.span`
  color: ${(props) => props.theme.colors.subPoint};
`;

export const MainPageTitle = styled.div`
  color: #333;
  font-size: 40px;
  font-weight: 700;
`

export const MainPageSubTitle = styled.div`
  color: #004BFA;
  font-size: 28px;
  font-weight: 700;
`;

export const MainDesc = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #999;
`;

type defaultType = {
  type?: string;
  gap?: number;
};
export const ScrollArea = styled.div<defaultType>`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: ${(props) => (props.gap !== null ? props.gap + "px" : 0)};
  -webkit-overflow-scrolling: touch; /* 끝에서 바운스 되도록*/
  scroll-snap-type: x mandatory;
  
  > div {
    flex: 0 0 auto;
    scroll-snap-align: start;
  }
`;
