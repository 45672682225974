import { NavLink, Link } from "react-router-dom";
import styled from 'styled-components';
import { themeDefault } from "../../themes/default";
import { HeadLine6 } from "../typography";

type LinksType = {
  color?: string;
}

const NavLinks = ({color} : LinksType) => {
  return (
    <>
      <LinkTab color={color}>
        <a href='https://funding.bankcow.co.kr'
          className='inactive'
        >
          한우투자
        </a>
      </LinkTab>
      <LinkTab color={color}>
        <NavLink to="story"
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          회사소개
        </NavLink>
      </LinkTab>
      <LinkTab color={color}>
        <NavLink to="notice"
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          고객센터
        </NavLink>
      </LinkTab>
      <LinkTab color={color}>
        <NavLink to="nowbancow"
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          공지사항
        </NavLink>
      </LinkTab>
      <LinkTab color={color}>
        <NavLink to="inquiry"
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          입점/제휴문의
        </NavLink>
      </LinkTab>
    </>
  )
}

const LinkTab = styled(HeadLine6)`
	// color: ${themeDefault.alt.text1};
	color: ${props => props.color || '#000'};
  font-weight: 700;
  a {
    color: inherit;
  }
	a.active {
		color: ${props => props.color || '#0048fa'};
	}
	@media ${themeDefault.breakpoints.sm} {
		color: ${themeDefault.colors.white};
		a.active & {
			color: ${themeDefault.colors.gray4};
		}
	}
`;

// const LinkTab = styled.div`
// `

export default NavLinks
