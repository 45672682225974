import styled from "styled-components";
import { themeDefault } from "../../themes/default";
import { Col, Flex } from "../layouts";
import useDeviceDetect from "../util/useDeviceDetect";
import footerlogo from "./footerlogo.png";
import whitefooterlogo from "./white_footerlogo.png";

type CompayLinkType = {
  themeType?: string;
};

const CompanyLinks = ({ themeType }: CompayLinkType) => {
  const { isMobile } = useDeviceDetect();
  return (
    <CompanyLinksStyle>
      {/* <img src="companylogo.png" alt="company logo" /> */}
      <LinkIconContainer>
        <Row>
          {themeType === "" ? (
            <>
              <a
                href="https://blog.naver.com/bancow-official"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/naver_blog_black.png"
                  alt="naverblog link"
                  width={40}
                />
              </a>
              <a
                href="https://post.naver.com/bcowmkt"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/naver_post_black.png"
                  alt="naverpost link"
                  width={40}
                />
              </a>
              <a
                href="https://pf.kakao.com/_dzLCs"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/kakao.png"
                  alt="kakao channel link"
                  width={40}
                />
              </a>
              <a
                href="https://bancow-design.tistory.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/tistory.png"
                  alt="tistory link"
                  width={40}
                />
              </a>
              <a
                href="https://www.youtube.com/@bancow1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/youtube_black.png"
                  alt="youtube link"
                  width={40}
                />
              </a>
              <a
                href="https://www.instagram.com/bancow_official/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/linkicons/insta_black.png"
                  alt="instagram link"
                  style={{ backgroundColor: "white", borderRadius: "9px" }}
                  width={40}
                />
              </a>
            </>
          ) : (
            <>
              <div>
                <a
                  href="https://blog.naver.com/bancow-official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/naver_blog_black.png"
                    alt="naverblog link"
                    width={40}
                  />
                </a>
                <a
                  href="https://post.naver.com/bcowmkt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/naver_post_black.png"
                    alt="naverpost link"
                    width={40}
                  />
                </a>
                <a
                  href="https://pf.kakao.com/_dzLCs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/kakao.png"
                    alt="kakao channel link"
                    width={40}
                  />
                </a>
                <a
                  href="https://bancow-design.tistory.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/tistory.png"
                    alt="tistory link"
                    width={40}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@bancow1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/youtube_black.png"
                    alt="youtube link"
                    width={40}
                  />
                </a>
                <a
                  href="https://www.instagram.com/bancow_official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/linkicons/insta_black.png"
                    alt="instagram link"
                    style={{ backgroundColor: "white", borderRadius: "9px" }}
                    width={40}
                  />
                </a>
              </div>
            </>
          )}
        </Row>
        <Row>
          <img
            src={
              "https://resources.stockeeper.in/assets/images/award/gd_logo.png"
            }
            width={"52px"}
            alt={"Good Desgin Logo"}
          />
        </Row>
      </LinkIconContainer>
      {isMobile && (
        <Partner>
          (주) 스탁키퍼파트너스 | 대표 안재현 <br />
          경기도 광주시 곤지암읍 광여로 4번길 31 <br />
          사업자등록번호 | 531-81-02137
        </Partner>
      )}
      <Text>
        {themeType === "" ? (
          <img
            src={footerlogo}
            alt="company logo"
            style={{ display: "block" }}
          />
        ) : (
          <img
            src={whitefooterlogo}
            alt="company logo"
            style={{ display: "block" }}
          />
        )}
        © 2022 Stockeeper. All rights reserved.
        <br />
        (주) 스탁키퍼 | 대표 안재현
        <br />
        서울특별시 영등포구 의사당대로 83 17층
        <br />
        사업자등록번호 | 574-81-01983
        <br />
        통신판매업신고번호 | 제 2022-서울마포-0673호
      </Text>
    </CompanyLinksStyle>
  );
};

const Text = styled.div`
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  @media ${themeDefault.breakpoints.sm} {
    font-size: 10px;
  }
  img {
    margin-bottom: 6px;
  }
`;

const CompanyLinksStyle = styled.div`
  display: flex;
  margin-top: 51px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  @media ${themeDefault.breakpoints.sm} {
    flex-direction: column-reverse;
  }
  ${Text} {
    img {
      @media ${themeDefault.breakpoints.sm} {
        margin-bottom: 15px;
        width: 80px;
        height: 22.75px;
      }
    }
  }
`;

const Partner = styled.div`
  margin-top: 13px;
  color: #999999;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  margin-left: 180px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 36px;
    margin-left: 0px;
  }
`;

const LinkIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  @media ${themeDefault.breakpoints.sm} {
    gap: 0px;
  }
`;

export default CompanyLinks;
