import classNames from 'classnames';
import React from 'react'
import ReactDOM from 'react-dom';

type Popuptype = {
  children: any;
  classname?: string;
  dim?: boolean;
}

const Popup = ({ children, classname, dim }: Popuptype) => {
  return ReactDOM.createPortal(
    <div className='popup-wrap'>
      <div className={classNames('', classname)}>{children}</div>
      {
        dim && 
        <div className="is-dim"></div>
      }
      {/* <div style={overlayStyle}></div> */}
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
}

export default Popup