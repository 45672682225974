import classNames from 'classnames';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../../common/global/Footer';
import { BtnWrap, Container } from '../../common/layouts';
import { MainDesc, MainPageTitle } from '../../common/typography';
import { themeDefault } from '../../themes/default';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import './event.scss'

const Event = () => {
  const [isEnd, setIsEnd] = useState(false);
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/inquiry' && window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <EventPage className="eventpage">
        <div className="head-area">
          <MainPageTitle className="maintitle">입점 및 제휴문의</MainPageTitle>
          <div className="desc">뱅카우 농장으로 입점하고 싶다면 입점신청을 <br />뱅카우와 사업제휴를 원하시면 제휴문의를 선택해주세요!</div>
          <div className="subdesc">홈페이지 문의 평균 답변 소요시간 : 약 3일 이내</div>
        </div>

        <div className="content-area">
          <MainPageTitle className="submaintitle">농장을 운영하고 계신가요?</MainPageTitle>
          <p>농장 입점신청을 진행하시면 영업일 기준 3일 이내로 답변을 받으실 수 있습니다.</p>

          <div className="card-wrap">
            <Card className="card">
              <div className="mark">Step 01</div>
              <strong>입점신청</strong>
              <ul>
                <li>사육인 정보</li>
                <li>사료 정보</li>
              </ul>
            </Card>

            <Card className="card">
              <div className="mark">Step 02</div>
              <strong>필수 구비 서류 등록 및 실사</strong>
              <ul>
                <li>개인 정보 등록</li>
                <li>농가 정보 등록 (필수서류제출)</li>
              </ul>
              <p>가축사육업 등록증, 축사시설 구조도, 사료비 명세서<br /> 출하성적서, 사업자등록증</p>
            </Card>

            <Card className="card">
              <div className="mark">Step 03</div>
              <strong>입점완료 및 펀딩 시작</strong>
            </Card>
          </div>

          <BtnWrap className='btn-wrap btn_wrap2' gap={20}>
            <a className='btn' href="/뱅카우설명서_생산자편.pdf" download target="_blank">농장 브로셔 다운</a>
            <Link to="/inquiry/regist">
              <div className='btn is-active'>농장 입점신청하기</div>
            </Link>
          </BtnWrap>

          <MainPageTitle className="second-title">뱅카우와의 제휴를 희망하시나요?</MainPageTitle>
          <p>제휴문의를 진행하시면 영업일 기준 3일이내로 답변을 받으실 수 있습니다.</p>
          <BtnWrap className='btn_wrap btn-wrap second' gap={20}>
            {/* <Link to="/howto/qna">
            <div className='btn is-active'>제휴 문의하기</div>
          </Link> */}
            <Link
              className='btn is-active'
              to='#'
              onClick={(e) => {
                window.location.href = 'mailto:pr@stockeeper.co';
                e.preventDefault();
              }}
            >제휴 문의하기 </Link>
          </BtnWrap>
        </div>
      </EventPage>
    </>
  );
};

const Card = styled.div`
  width: 300px;
  height: 172px;
  box-shadow: 2px 8px 20px 6px #E8EFFF;
  border-radius: 20px;   
  padding: 16px;
  .mark {
    font-size: 14px;
    color: #8EB0FF;
    font-weight: 700;
  }
  strong {
    display: block;
    margin-top: 14px;
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  ul {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin-top: 15px;
    line-height: 23px;
  }
`

const EventPage = styled.div`
  .card-wrap {
    display: flex;
    gap: 60px;
    margin-top: 60px;
    @media ${themeDefault.breakpoints.sm} {
      flex-direction: column;
      align-items: center;
      margin-top: 36px;
      gap: 36px;
    }
  }
  ${MainPageTitle} {
    &.center {
      text-align: center;
    }
    + p {
      color: #333;
      margin-top: 24px;
      font-weight: 500;
      @media ${themeDefault.breakpoints.sm} {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .second-title {
    margin-top: 200px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 100px;
      font-size: 24px;
    }
  }
  .btn-wrap {
    margin-top: 76px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 48px;
    }
    &.second {
      margin-top: 77px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 39px
      }
    }
  }
`



export default Event;
