import classNames from 'classnames';
import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { BtnWrap, Container } from '../../common/layouts';
import useDeviceDetect from '../../common/util/useDeviceDetect';
import { themeDefault } from '../../themes/default';
import DropDown from '../dropbox/DropDown';
import styles from './howto.module.scss';
import { useFaqCategory, useFaqDetail, useFaqList } from "../../hooks/useFaq";
import axios from "axios";
import HowToUseDetail from './HowToUseDetail';
import './howto.scss';
import { faqCategoryProp, faqProp } from "../../types";
import AppLoading from "../common/AppLoading";
import Loading from '../Loading/Loading';
import Pagenation from '../pagenation/Pagenation';
import ReactGA from 'react-ga';
import _ from 'lodash';

const PostLink = (props: any) => (
  // <Link to="/howto/faq/[id]" as={`/howto/faq/${props.id}`}>
  // <Link to="/howto/faq/[id]">
  <Link to={{
    pathname: `/notice/faq/${props.id}`,
  }}>
    {props.title}
  </Link>
)

const HowToUse = () => {
  const location = useLocation();
  const [faqRequest, setFaqRequest] = useState({
    faq_category_id: 0,
    title: '',
    search_page: 1,
    search_count: 10,
  });
  const [totalProp, setTotalProp] = useState({
    totalPages: 0,
    totalElements: 0
  });
  const [detailBool, setDetailBool] = useState<boolean>(false);
  const [faqId, setFaqId] = useState<number>(0);

  const [faqList, setFaqList] = useState<any[]>([]);
  const [tabClickIndex, setTabClickIndex] = useState(0);
  // const [listClick, setListClick] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchFaqList = () => {
    return new Promise(resolve => {
      axios.post(`${process.env.REACT_APP_BASE_API}faq/public/list`, faqRequest)
        .then(result => {
          setFaqList(result?.data?.data?.content);
          resolve(result?.data?.data?.content);
          setTotalProp({
            ...totalProp,
            totalPages: result?.data?.data?.totalPages,
            totalElements: result?.data?.data?.totalElements
          });
        })
        .catch(error => {
          resolve(false);
        });
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    getDebounce(e)
  }

  const getDebounce = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    setFaqRequest({
      ...faqRequest,
      title: e.target.value,
      search_page: 1,
      search_count: 10
    });
  }, 500);

  const handleClick = (faq_category_id: number, category_index: number) => {
    setLoading(true);
    setFaqRequest({
      ...faqRequest,
      faq_category_id,
      title: '',
      search_page: 1,
      search_count: 10,
    });
    setDetailBool(false);
    setTabClickIndex(category_index);
  }

  const { isLoading, data } = useFaqCategory();

  const { isMobile } = useDeviceDetect();

  const dropdownvalue = (value: any) => {
    // setSelectV(value);
    handleClick(value, 0);
  }

  const questionListClick = (id: number) => {
    setFaqId(id);
    // setListClick(!listClick);
    setDetailBool(true);
  }

  const getPaging = (value: number) => {
    setFaqRequest({
      ...faqRequest,
      search_page: value + 1
    })
  }

  const clearQuestionListClick = () => {
    setDetailBool(false);
    setFaqId(0);
  }

  useEffect(() => {
    (!isMobile && location.pathname === '/notice') && window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location, isMobile])

  useEffect(() => {
    fetchFaqList();
    !loading && setLoading(true);
    fetchFaqList().then(() => setLoading(false));
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [faqRequest]);

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);


  return (
    <Section className="howtouse">
      {/* <Title>뱅카우 하는 법 알아볼까요?</Title>
			<Img1 url="/howtopage/howto1.png" />
			<StepContentList trigger={trigger} />
			{isOpen && <GuideModal setIsOpen={setIsOpen} step={step} data={data} />} */}
      <div className="inner">
        <Container>
          <div className="page_title">안녕하세요! <br />뱅카우 고객센터입니다.</div>
          <div className="desc">뱅카우 앱 내 문의하기를 활용하시면 더욱 <br className='m-layer' />빠른 답변을 받으실 수 있어요!</div>
          <div className='img'></div>
          <div className="sub_desc">홈페이지 문의 평균 답변 소요시간: 약 3일 이내</div>
          <BtnWrap className="btn-wrap btn_wrap" gap={20}>
            {/* <div className='btn'>답변확인</div> */}
            <Link to="/howto/qna">
              <div className='btn is-active'>문의하기</div>
            </Link>
          </BtnWrap>
        </Container>
      </div>

      <Container>
        <div className="question_area">
          <div className="title">자주 묻는 질문</div>
          <div className="inputwrap_search">
            <input type="text"
              placeholder='제목을 검색해주세요'
              onChange={handleChange}
            />
          </div>
          <div className="list">
            {
              !isMobile &&
              <Type className='type pc-layer'>
                <ul>
                  {
                    isLoading ? (
                      <>
                        <Loading />
                      </>
                    ) : (
                      <>
                        <li
                          id={`${0}`}
                          className={classNames('',
                            { 'is-select': 0 === tabClickIndex }
                          )}
                          onClick={() => handleClick(0, 0)}
                        >
                          전체
                        </li>
                        {
                          data?.data?.data.map((category, idx) => {
                            return (
                              <li
                                key={idx + 1}
                                id={`${idx + 1}`}
                                className={classNames('',
                                  { 'is-select': idx + 1 === tabClickIndex }
                                )}
                                onClick={() => handleClick(category.id, idx + 1)}
                              >
                                {category.category}
                              </li>
                            )
                          })
                        }
                      </>
                    )
                  }
                </ul>
              </Type>
            }
            <div className="m-layer">
              <DropDown dropdownvalue={dropdownvalue} faqCategoryList={data?.data?.data} filterData={1} dataName="faq" />
            </div>
            {/* 목록 */}
            <div className="question_list">
              {
                loading ? (
                  <AppLoading />
                ) : detailBool ? (
                  <HowToUseDetail faqId={faqId} onBackClick={clearQuestionListClick} />
                ) : isMobile ? (
                  <ul>
                    {
                      faqList.map((e, idx) => {
                        return (
                          <li key={e.id} onClick={() => questionListClick(e.id)}>
                            <PostLink id={e.id} title={e.title} />
                          </li>
                        )
                      })
                    }
                  </ul>
                ) : (
                  <>
                    <ul>
                      {
                        faqList.map((e, idx) => {
                          return (
                            <li key={idx} onClick={() => questionListClick(e.id)}>
                              <p>{e.title}</p>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </>
                )
              }
              {
                // faqRequest
                faqList.length === 0 && <div className='not-result'>검색결과가 없습니다.</div>
              }
              {
                !detailBool &&
                <Pagenation
                  align={isMobile ? '' : 'left'}
                  activeStep={faqRequest.search_page - 1}
                  pagingCount={totalProp.totalPages}
                  viewCount={faqRequest.search_count}
                  getPaging={getPaging}
                />
              }
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

const Type = styled.div`
  width: 200px;
  border-right: 2px solid #EBEBEB;
  li {
    font-size: 24px;
    font-weight: 500;
    &.is-select {
      font-weight: 700;
    }
  }
`

const Section = styled.div`
	// padding: 120px 0 0 0;
  margin-top: 68px;
  padding-top: 0!important;
  @media ${themeDefault.breakpoints.sm} {
    padding-top: 68px!important;
  }
  .inner {
    padding: 160px 0 122px 0;
    background:  #F9FAFB;
    @media ${themeDefault.breakpoints.sm} {
      padding: 100px 0;
    }
  }
	@media ${themeDefault.breakpoints.sm} {
		padding: 80px 0 0;
    margin-top: 0;
	}
  .mt-16 {
    margin-top: 16px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 200px;
  }
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;



export default HowToUse;
