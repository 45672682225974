import React, { useState } from 'react';
import DaumPostcode from "react-daum-postcode";
import './popup.scss';

type PostCodeType = {
  onClose?: () => void;
  addressData?: (value: string) => void;
}

const PopupPostCode = ({ onClose, addressData = (value: string) => '' }: PostCodeType) => {
  const [localData, setLocalData] = useState('');

  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    // setLocalData(data?.zonecode + ' ' + fullAddress);
    setLocalData(fullAddress);
    addressData(fullAddress);
    // onClose()
  }


  return (
    <div className="popup postcode">
      <DaumPostcode onComplete={handlePostCode} className="postcode" />
      <div className="btn-wrap">
        <button type='button' className='close btn' onClick={onClose}>닫기</button>
      </div>
    </div>
  )
}

export default PopupPostCode;