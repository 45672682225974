import { termsResponse } from "../types";
import axios, {AxiosResponse} from "axios";
import {useQuery, UseQueryResult} from "react-query";


type termsReturnType = AxiosResponse<termsResponse>;

const apiUrl = "https://prod.stockeeper.in/bancow/api/v1/auth/termService";

const termsApi = (prop: string) => axios.get(`${apiUrl}`, {params: {terms_service_code: prop}});

export const useTerms = (prop: string): UseQueryResult<termsReturnType, Error> => {
    return useQuery(['terms'], () => termsApi(prop));
}