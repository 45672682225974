import { DownloadAppIconBox } from './layouts';
import BrowserGoogleApp from '../assets/icon/BrowserGoogleApp';
import MobileGoogleApp from '../assets/icon/MobileGoogleApp';
import styles from './AppStyle.module.scss';

function GoogleApp() {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.stockeeper.stockeeperapp"
      target="_blank"
      rel="noreferrer"
    >
      <DownloadAppIconBox>
        <div className={`${styles.googleapp} ${styles.app_img}`}></div>
      </DownloadAppIconBox>
    </a>
  );
}

export default GoogleApp;
