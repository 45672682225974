import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import './chart.scss';

const growth = [
  {
    name: "2011년",
    uv: 2819674,
  },
  {
    name: "2012년",
    uv: 2932815,
  },
  {
    name: "2013년",
    uv: 2810187,
  },
  {
    name: "2014년",
    uv: 2904274,
  },
  {
    name: "2015년",
    uv: 2768945,
  },
  {
    name: "2016년",
    uv: 2809820,
  },
  {
    name: "2017년",
    uv: 2871400,
  },
  {
    name: "2018년",
    uv: 2961521,
  },
  {
    name: "2019년",
    uv: 3078184,
  },
  {
    name: "2020년",
    uv: 3227181,
  },
  {
    name: "2021년",
    uv: 3384998,
  },
];

const ten = [
  {
    name: "2011년",
    uv: 5653269,
  },
  {
    name: "2012년",
    uv: 6062250,
  },
  {
    name: "2013년",
    uv: 5798795,
  },
  {
    name: "2014년",
    uv: 6293700,
  },
  {
    name: "2015년",
    uv: 7180230,
  },
  {
    name: "2016년",
    uv: 8201453,
  },
  {
    name: "2017년",
    uv: 7721560,
  },
  {
    name: "2018년",
    uv: 8243400,
  },
  {
    name: "2019년",
    uv: 8468388,
  },
  {
    name: "2020년",
    uv: 9452245,
  },
  {
    name: "2021년",
    uv: 10245484,
  }
]


type ToolType = {
  active?: any,
  payload?: any,
  label?: any,
  type?: string
}
const CustomTooltip = ({ active, payload, label, type }: ToolType) => {
  // console.log('payload :', payload && payload[0].payload.name, 'value :', payload && payload[0].value)
  if (active) {
    return (
      <div className="custom-tooltip">
        {`${label}`}
        <p className="label">
          {
            `${payload[0].value.toLocaleString()}`
          }
          {
            type === 'growth' ? '마리' : '원'
          }
        </p>
      </div>
    );
  }
  return null;
};

type DataName = {
  data?: string;
}
const AreaChartC = ({ data }: DataName) => {
  return (
    <div className='chart-area'>
      <div className="tick-area">
        {
          data === 'growth' ?
            <>
              <span>(만)</span>
              <span>300</span>
              <span>340</span>
            </> :
            <>
              <span>(십만)</span>
              <span>55</span>
              <span>102</span>
            </>
        }
      </div>
      <ResponsiveContainer width='100%' aspect={4.0 / 1.5}>
        <AreaChart
          data={
            data === 'growth' ? growth : ten
            // growth
          }
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stopColor="#004bfa" stopOpacity={1} />
              <stop offset="100%" stopColor="#004bfa" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: '#999' }} />
          <YAxis tickLine={false} axisLine={false} unit="만" tickCount={4}
            domain={
              data === 'growth' ?
                // [(dataMin: any) => (0), (dataMax: any) => (dataMax)]
                [2600000, 3400000]
                :
                [8200, 22000]
            }
          // allowDataOverflow={true}
          />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip type={data} />} />
          <Area type="monotone" dataKey="uv" stroke="#004bfa" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer >
    </div>
  )
}

export default AreaChartC

