import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Steps from '../../../components/steps/Steps';
import { MainPageTitle } from '../../../common/typography';
import { Container } from '../../../common/layouts';
import styles from './regist.module.scss';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '../../../components/button/Button';
import DropDown from '../../../components/dropbox/DropDown';
import PopupPostCode from '../../../components/popup/PopupPostCode';
import useFarmQna from "../../../hooks/useFarmQna";
import axios from "axios";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import Checkbox from "../../../components/input/Checkbox";
import { useLocation } from 'react-router-dom';
import Popup from '../../../components/popup/Popup';
import { getInsertToken } from "../../../hooks/axiosInsertToken";

type farmQnaRequestProp = {
  "farm_qna_name": string;
  "phone_number": string;
  "email": string;
  "farm_name": string;
  "farm_address": string;
  "cow_num": number;
  "feed_name": string;
}

const Regist = () => {
  // @ts-ignore
  const [qnaRequest, setQnaRequest] = useState<farmQnaRequestProp>({
    "farm_qna_name": "",
    "phone_number": "",
    "email": "",
    "farm_name": "",
    "farm_address": "",
    "cow_num": 0,
    "feed_name": "",
  });
  const [step, setStep] = useState(0); // step is 0, 1, 2, error = 2 && errorState true
  const [errorState, setErrorState] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [getData, setGetData] = useState('');
  const [personalAgree, setPersonalAgree] = useState(false);
  const [formOkChk, setFormOkChk] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [token, setToken] = useState<any>();
  const [stepBool, setStepBool] = useState<boolean>(false);

  const formRef = useRef<any>()

  const handleSubmit = async (fieldValues: any) => {
    setSubmitting(true);
    setQnaRequest({
      ...qnaRequest,
      ...fieldValues,
    })

    axios.post(`${process.env.REACT_APP_BASE_API}farmqna/add`, {
      ...qnaRequest,
      ...fieldValues,
      farm_address: `${getData}, ${fieldValues?.farm_address}`,
      insert_token: token
      // farmQnaName: qnaRequest.farm_qna_name,
      // phoneNumber: qnaRequest.phone_number,
    })
      .then(result => {
        setStep(2);
        setSubmitting(false);
      })
      .catch(error => {
        setErrorState(true);
        setStep(2);
        setSubmitting(false);
      });
  }

  const stepNext = async (step: number) => {
    setStep(step);
    if (stepBool === false) {
      const data = await getInsertToken();
      if (data === 'TK01')
        alert("죄송합니다. 다시 시도해주세요");
      else
        setToken(data);
      setStepBool(true);
    }
    setFormOkChk(false);
  }

  const stepReset = () => {
    setStep(0);
    setQnaRequest({
      ...qnaRequest,
      "farm_qna_name": "",
      "phone_number": "",
      "email": "",
      "farm_name": "",
      "farm_address": "",
      "cow_num": 0,
      "feed_name": "",
    })
  }

  // 팝업창 열기
  const openPostCode = () => {
    setIsPopupOpen(true)
  }
  // 팝업창 닫기
  const closePostCode = () => {
    setIsPopupOpen(false)
  }
  // getData
  const getPopupData = (value: string) => {
    setGetData(value);
    // formRef.current?.setFieldValue('farm_address', value);
    closePostCode();
  }

  const _handleSubmitForm1 = (values: any) => {
    setQnaRequest({
      ...qnaRequest,
      "farm_qna_name": values.farm_qna_name,
      "phone_number": values.phone_number,
      "email": values.email,
    });
    stepNext(1);
  }

  const ClickStep = (value: number) => {
    if (step === 2 && !errorState) return;
    setStep(value);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    })
    return () => {
      // @ts-ignore
      setQnaRequest({
        "farm_qna_name": "",
        "phone_number": "",
        "email": "",
        "farm_name": "",
        "farm_address": "",
        "cow_num": 0,
        "feed_name": ""
      });
    }
  }, []);

  return (
    <QnaPage className={classNames(styles.qna_page, 'form-page')}>
      <Steps activeStep={step} ClickStep={ClickStep} />
      {isPopupOpen && (
        <Popup dim={true}>
          <PopupPostCode onClose={closePostCode} addressData={getPopupData} />
        </Popup>
      )}
      {
        step === 0 &&
        <>
          <MainPageTitle>연락드릴 정보를 알려주세요!</MainPageTitle>
          <div className="form">
            <Formik
              initialValues={{
                farm_qna_name: qnaRequest.farm_qna_name,
                phone_number: qnaRequest.phone_number,
                email: qnaRequest.email,
                personal_agree: false,
              }}
              enableReinitialize
              onSubmit={_handleSubmitForm1}
              validationSchema={Yup.object().shape({
                farm_qna_name: Yup.string()
                  .trim()
                  .required('다시 확인해주세요.')
                  .min(2, '신청자 성함을 입력해주세요.'),
                phone_number: Yup.string()
                  .trim()
                  .required("다시 확인해주세요.")
                  .min(11, "신청자 핸드폰 번호를 입력해주세요."),
                email: Yup.string(),
                personal_agree: Yup.boolean()
                  .oneOf([true], '개인정보 수집이용에 동의해주세요.'),
              })}
            >
              {({ handleSubmit, isValid, dirty }) => (
                <>
                  <Field
                    component={Input}
                    label="* 이름"
                    id="farm_qna_name"
                    name="farm_qna_name"
                    placeholder='이름 입력'
                    required
                  />
                  <Field
                    component={Input}
                    label="* 휴대전화 번호"
                    id="phone_number"
                    name="phone_number"
                    placeholder="'-'를 제외하고 입력주세요."
                    required
                  />
                  <Field
                    component={Input}
                    label="이메일"
                    id="email"
                    name="email"
                    placeholder='abcd@example.com'
                    required
                  />
                  <Field
                    component={Checkbox}
                    label="문의 내용 답변을 위한 개인정보 수집이용에 동의합니다."
                    id="personal_agree"
                    name="personal_agree"
                    required
                  />
                  <div className="alert-noti">잘못 입력하실 경우 답변을 받기 어려울수 있습니다.</div>
                  <div className="btn-wrap">
                    {/*@ts-ignore*/}
                    <div className={classNames("btn is-active", !isValid || !dirty ? 'is-disabled' : '')} onClick={handleSubmit} >다음</div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </>
      }
      {
        step === 1 &&
        <>
          <MainPageTitle>농장 정보를 알려주세요!</MainPageTitle>
          <div className="form">
            <Formik
              initialValues={{
                farm_name: qnaRequest.farm_name,
                farm_address: qnaRequest.farm_address,
                cow_num: qnaRequest.cow_num,
                feed_name: qnaRequest.feed_name,
              }}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                farm_name: Yup.string(),
                farm_address: Yup.string(),
                cow_num: Yup.number(),
                feed_name: Yup.string().trim().required("사료명을 확인해주세요."),
              })}
              innerRef={formRef}
            >
              {({ handleSubmit, dirty, isValid }) => (
                <>
                  <Field
                    component={Input}
                    label="농장이름"
                    id="farm_name"
                    name="farm_name"
                    placeholder='농장 이름 입력'
                    required
                  />
                  <div className="inputwrap">
                    <label htmlFor="">
                      <strong>농장주소</strong>
                      <input type="text" placeholder='주소 검색' className='cursor-pointer' readOnly onClick={openPostCode} value={getData} />
                      <Field
                        component={Input}
                        // label="농장주소"
                        id="farm_address"
                        name="farm_address"
                        placeholder='상세 주소 입력'
                        required
                        style={{ marginTop: 0 }}
                      />
                    </label>
                  </div>
                  <Field
                    component={Input}
                    label="사육두수"
                    id="cow_num"
                    name="cow_num"
                    placeholder='사육 중인 소의 개체수를 입력해주세요.'
                    required
                    type="number"
                  />
                  <Field
                    component={Input}
                    label="* 사용 사료"
                    id="feed_name"
                    name="feed_name"
                    placeholder='사료명 입력'
                    required
                  />
                  <div className="btn-wrap">
                    {/*@ts-ignore*/}
                    <div className={classNames("btn is-active", submitting || !isValid || !dirty ? 'is-disabled' : '')} onClick={handleSubmit} >다음</div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </>
      }
      {
        (step === 2 && !errorState) && (
          <>
            <MainPageTitle>입점신청이 완료되었습니다.</MainPageTitle>
            <p className='end-ment'>답변은 약 3일 이내로 확인하실 수 있습니다. <br />감사합니다!</p>
            <div className={classNames("btn-wrap", `grade-${step}`)}>
              <div className={classNames("btn is-active")} onClick={() => window.location.href = "/inquiry"}>확인</div>
            </div>
          </>
        )
      }
      {
        (step === 2 && errorState) && (
          <>
            <MainPageTitle className={styles.error_title}>죄송합니다.</MainPageTitle>
            <p className={classNames('end-ment', `state-${errorState}`)}>다시 시도해주세요.</p>
            <div className={classNames("btn-wrap", `grade-${step}`)}>
              <div className={classNames("btn is-active", `state-${errorState}`)} onClick={stepReset} >돌아가기</div>
            </div>
          </>
        )
      }
    </QnaPage>
  )
}

const QnaPage = styled(Container)`
  ${MainPageTitle} {
    margin-top: 44px;
    margin-bottom: 60px;
  }
`

export default Regist;