import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CountUp, { useCountUp } from 'react-countup';
// import AnimatedNumber from "react-animated-numbers";
import { Container } from '../../../common/layouts';
import { themeDefault } from '../../../themes/default';
import './home.scss';
import useDeviceDetect from 'src/common/util/useDeviceDetect';

const Counting = () => {
  const [ScrollY, setScrollY] = useState(0);  // 스크롤값을 저장하기 위한 상태
  const { isMobile } = useDeviceDetect();

  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    }
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    }
  })

  return (
    <CountingStyle className="map-area">
      <TextWrap>
        <TextStyle>
          <strong>뱅카우에서 <br />현재 키우고 있는 소는?</strong>
          {/* <div>1,100마리+</div> */}
          {
            ScrollY > 570 ?
              <div>
                <CountUp start={1.000}
                  end={1.400}
                  separator=" "
                  duration={1}
                  delay={0} decimals={3}
                  decimal="," />
                마리+
              </div> :
              <div>1,400마리+</div>
          }
        </TextStyle>
        <TextStyle>
          <strong>뱅카우<br />펀딩 참여 수는?</strong>
          {
            ScrollY > 1000 ?
              <div>
                <CountUp start={10.000}
                  end={15.000}
                  separator=" "
                  duration={1}
                  delay={0} decimals={3}
                  decimal="," />
                +
              </div> :
              <div>15,000+</div>
          }
        </TextStyle>
        <span>2022.09 기준</span>
      </TextWrap>
      <div className='imgwrap'>
        {
          isMobile ?
            <img src="/mainpage/mobilemap.png" alt="지도" /> :
            <img src="/mainpage/map.png" alt="지도" />
        }
      </div>
    </CountingStyle>
  )
}



const TextStyle = styled.div`
  strong {
    color: #999;
    font-size: 24px;
    line-height: 32px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  div {
    margin-top: 24px;
    font-size: 100px;
    font-weight: 700;
    line-height: 120px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 50px;
      line-height: 1;
      margin-top: 40px;
    }
  }
`

const TextWrap = styled.div`
  ${TextStyle} {
    + span {
      display: block;
      margin-top: 32px;
      font-weight: 500;
      color: #999;
      font-size: 16px;
      @media ${themeDefault.breakpoints.sm} {
        margin-top: 14px;
        font-size: 12px;
      }
    }
  }
  @media ${themeDefault.breakpoints.sm} {
    padding: 36px 0 0 24px;
  }
`

const CountingStyle = styled(Container)`
  display:flex;
  justify-content: space-between;
  margin-top: 164px;
  @media ${themeDefault.breakpoints.sm} {
    flex-direction: column-reverse;
    margin-top: 0;
  }
  ${TextWrap} {
    ${TextStyle} {
      + ${TextStyle} {
        margin-top: 145px;
        @media ${themeDefault.breakpoints.sm} {
          margin-top: 81px;
        }
      }
    }
  }
`

export default Counting