import { HeadLine2 } from '../../../common/typography';
import styled from 'styled-components';
import { themeDefault } from '../../../themes/default';
import './home.scss';

const SloganText = ({ showMainSloganText = true, showSubSloganText = true }) => {
	return (
		<div>
			{
				showMainSloganText && <div className='main-text'>
					송아지 키우며 자산을 늘려요
				</div>
			}
			{showSubSloganText && <div className='main-text-small'>한우펀딩플랫폼 뱅카우</div>}
		</div>
	);
};

export default SloganText;
