import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Container, Radius30WhiteBox, HomeTag, Flex } from '../../../common/layouts'
import { MainPageTitle, ScrollArea } from '../../../common/typography';
import { useHorizontalScroll } from '../../../common/hooks/useHorizontalScroll';
import styles from '../homepage.module.scss';
import axios from 'axios';
import { themeDefault } from '../../../themes/default';
import useBuyer from "../../../hooks/useBuyer";
import Slider from "react-slick";
import './review.scss';
import CssSlide from 'src/components/Slide/CssSlide';
import Loading from 'src/components/Loading/Loading';

const Review = () => {
  const scrollRef = useHorizontalScroll();
  const [getData, setGetData] = useState([]);
  const baseApi = process.env.REACT_APP_PUBLIC_BASE_API;

  const { isLoading, isError, data } = useBuyer();

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <ReviewStyle>
      <Container>
        <MainPageTitle>
          뱅카우 고객 후기
        </MainPageTitle>
      </Container>

      {
        isLoading || isError ? (
          <div className="review-slide">
            <Loading />
          </div>
        ) : (
          <CssSlide className="review-slide">
            {
              data?.data?.data.map((item, idx) => {
                return (
                  <Card className={styles.review_card} key={idx}>
                    <div className="profile">
                      <div className="imgwrap">
                        <img src={require(`../img/profile_0${idx + 1}.png`)} alt="" />
                      </div>
                      <div className="text">
                        <strong>
                          {item.buyerName}
                        </strong>
                        <div className='date'>{item.reviewDate}</div>
                      </div>
                    </div>
                    <p>{item.comment}</p>
                  </Card>
                )
              })
            }
            {
              data?.data?.data.map((item, idx) => {
                return (
                  <Card className={styles.review_card} key={idx}>
                    <div className="profile">
                      <div className="imgwrap">
                        <img src={require(`../img/profile_0${idx + 1}.png`)} alt="" />
                      </div>
                      <div className="text">
                        <strong>
                          {item.buyerName}
                        </strong>
                        <div className='date'>{item.reviewDate}</div>
                      </div>
                    </div>
                    <p>{item.comment}</p>
                  </Card>
                )
              })
            }
          </CssSlide>
        )
      }
    </ReviewStyle>
  )
}

const Card = styled(Radius30WhiteBox)`
  width: 360px;
  height: 430px;
  padding: 30px;
  @media ${themeDefault.breakpoints.sm} {
    width: 192px;
    height: 308px;
    padding: 20px 16px;
  }
  .profile {
    display: flex;
    align-items: center;
  }
  .imgwrap {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #c0efff;
  }
  strong {
    display: inline-block;
    margin-left: 24px;
    font-size: 20px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  p {
    margin-top: 36px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 16px;
      font-size: 14px;
      line-height: 26px;
    }
  }
`

const ReviewStyle = styled.div`
  margin: 269px 0 220px 0;

  main {
    overflow-x: hidden;
    display: flex;
  }
  section {
    min-width: 50vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4ch;
  }
  @media ${themeDefault.breakpoints.sm} {
    margin: 120px 0 107px 0;
    padding: 0 20px;
  }
  ${MainPageTitle} {
    @media ${themeDefault.breakpoints.sm} {
      font-size: 28px;
    }
  }
  ${ScrollArea} {
    margin-top: 67px;
    padding-bottom: 40px;
    @media ${themeDefault.breakpoints.sm} {
      margin-top: 16px;
    }
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
`

export default Review