import React from 'react'
import styled from 'styled-components'
import { Container } from '../../common/layouts'
import { MainDesc, MainPageTitle } from '../../common/typography'
import { themeDefault } from '../../themes/default'

const Tutorial = () => {
  return (
    <TutorialStyle>
      <MainPageTitle>뱅카우 튜토리얼</MainPageTitle>
      <MainDesc>즐거운 한우 펀딩, 그 시작을 함께</MainDesc>
      {/* <video src="">비디오</video> */}
      <video id="tutorialVideo"
        poster="/tuto-thum2.png"
        controls={true}
      >
         <source src="https://d1c8bveijgbex6.cloudfront.net/video/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC_0613.mp4" type="video/mp4" />
      </video>
    </TutorialStyle>
  )
}

const TutorialStyle = styled(Container)`
  margin-top: 332px;
  @media ${themeDefault.breakpoints.sm} {
    margin-top: 120px;
    padding: 0 20px;
  }
  video {
    // height: 574px;
    background: #eee;
    @media ${themeDefault.breakpoints.sm} {
      // height: 200px;
    }
  }
  ${MainPageTitle} {
    margin-bottom: 24px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
  ${MainDesc} {
    margin-bottom: 33px;
    @media ${themeDefault.breakpoints.sm} {
      font-size: 16px;
      margin-bottom: 22px;
    }
  }
`

export default Tutorial