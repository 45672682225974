import React from 'react'
import styles from './history.module.scss';

const History2021 = () => {
  return (
    <div className={styles.story}>
      <strong>2021</strong>
      <ul>
        <li>
          <span>12</span>프리시리즈A 유치
        </li>
        <li>
          <span>11</span>DATA-STARS 최우수상 수상 (아산재단)
        </li>
        <li>
          <span>10</span>뱅카우 한우 상생농가 100두 돌파 <br />핀테크큐브 보육기업 협약 (한국핀테크센터)
        </li>
        <li>
          <span>08</span>서울창조경제혁신센터 보육기업 협약<br />제 10회 정주영 창업경진대회 최우수상 수상
        </li>
        <li>
          <span>07</span>매경핀테크경진대회 최우수상 수상 (매일경제) <br />신한인큐베이션 7기 보육기업 선정 (신한은행)
        </li>
        <li>
          <span>06</span>DATA-STARS 데이터 사업 활용기업 선정 (한국데이터산업진흥원) <br /> KDB NextONE 보육기업 선정 (KDB산업은행) <br /> K-Champ Challenge 유니콘클럽 혁신기업 선정 (KB국민은행) <br /> IBK창공 7기 혁신 창업기업 선정 (IBK기업은행)
        </li>
        <li>
          <span>05</span>한우펀딩플랫폼 뱅카우 런칭<br />
          제 10회 정주영 창업경진대회 보육기업 선정
        </li>
        <li>
          <span>04</span>혁신성장 유형 벤처기업 선정 (벤처기업협회)
        </li>
        <li>
          <span>03</span>가축투자플랫폼 기술 임치 (Tech Safe)
        </li>
        <li>
          <span>02</span>가축투자플랫폼 기술 보증 (기술보증기금)
        </li>
        <li>
          <span>01</span>한국핀테크센터 창업패키지 특화부문 선정 (최우수 기업 판정)
        </li>
      </ul>
    </div>
  )
}

export default History2021