import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './notfound.scss';

const NotFound404 = () => {
  return (
    <div className='notfound-page'>
      <InfoText>
        404 Error
        <img src="/errorpage/page404-2.png" alt="" className='img2'/>
      </InfoText>
      <Desc>
        요청하신 페이지를 찾을 수 없습니다.
        <br />
        입력하신 주소가 정확한지 다시 한번 확인해주세요.
      </Desc>
      <Desc>
        <Link to="/">
          <span>홈으로 돌아가기</span>
        </Link>
      </Desc>
      <img src="/errorpage/page404-1.png" alt="" className='img1'/>
    </div>
  );
};

const InfoText = styled.div`
	display: flex;
	align-items: center;
  font-weight: 700;
	font-size: 58px;
	line-height: 1.45;
	
`;

const Desc = styled.div`
	text-align: center;
	margin: 20px 0;
  font-size: 23px;
  line-height: 30px;
`;


export default NotFound404;
