import axios, {AxiosResponse} from "axios";
import {bannerResponse} from "../types";
import {useQuery, UseQueryResult} from "react-query";

type ReturnType = AxiosResponse<bannerResponse>;

const apiUrl = process.env.REACT_APP_BASE_API;

const bannerApi = () => axios.get(`${apiUrl}banner/list`);

const useBanner = (): UseQueryResult<ReturnType, Error> => {
    return useQuery(['banner'], () => bannerApi());
}

export default useBanner;